export const BaseLink = "https://test.odygo.pl/realizacje/";

export const ProjectData = [
  {
    name: "11-hornostaje",
    title: "Realizacja – Osada Hornostaje",
    description:
      "Kolejna realizacja w Osadzie Hornostaje, tym razem z 13 panelami Longi Solar i mocą 6kWp.",
    fluentLink:
      "fotowoltaika-osada-hornostaje-bialystok-podlasie-6kwp-panele-longi-solar-13szt",
    mainFile:
      "01-osada-hornostajei-bialystok-podlaskie-podlasie-6kwp-panele-longi-solar-13szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-6kwp-panele-longi-solar-13szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 13,
    shortTitle: "6kWp, 13 x Longi Solar",
    place: "Osada Hornostaje, Podlaskie",
  },
  {
    name: "04-bielsk",
    title: "Realizacja – Bielsk Podlaski",
    description:
      "Kolejna realizacja w Bielsku Podlaskim, tym razem z 30 panelami LG i mocą 10kWp.",
    fluentLink:
      "fotowoltaika-bielsk-bialystok-podlaskie-podlasie-10kwp-panele-LG-30szt",
    mainFile:
      "02-bielsk-bialystok-podlaskie-podlasie-10kwp-panele-LG-30szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-LG-30szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 10,
    shortTitle: "10kWp, 30 x LG",
    place: "Bielsk Podlaski, Podlaskie",
  },
  {
    name: "01-bransk",
    title: "Realizacja – Brańsk",
    description:
      "W Brańsku zainstalowaliśmy 156 paneli od Longi Solar o łącznej mocy 50kWp, co czyni tę instalację jedną z naszych największych.",
    fluentLink:
      "fotowoltaika-bransk-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt",
    mainFile:
      "03-bransk-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 10,
    shortTitle: "50kWp, 156 x Longi Solar",
    place: "Brańsk, Podlaskie",
  },
  {
    name: "02-bransk",
    title: "Realizacja – Brańsk",
    description:
      "W Brańsku zainstalowaliśmy 156 paneli od Longi Solar o łącznej mocy 50kWp, co czyni tę instalację jedną z naszych największych.",
    fluentLink:
      "fotowoltaika-bransk2-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt",
    mainFile:
      "04-bransk2-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 10,
    shortTitle: "50kWp, 156 x Longi Solar",
    place: "Brańsk, Podlaskie",
  },
  {
    name: "16-knyszyn",
    title: "Realizacja – Knyszyn",
    description:
      "W Knyszynie zrealizowaliśmy projekt z 32 panelami Ja Solar o mocy 17,3kWp, co pokazuje naszą wszechstronność i zdolność do dostosowania się do różnorodnych potrzeb.",
    fluentLink:
      "fotowoltaika-knyszyn-podlaskie-podlasie-17,3kwp-panele-jasolar-32szt",
    mainFile:
      "05-knyszyn-bialystok-podlaskie-podlasie-17,3kwp-panele-jasolar-32szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-17,3kwp-panele-jasolar-32szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 9,
    shortTitle: "17,3kWp, 32 x Ja Solar",
    place: "Knyszyn, Podlaskie",
  },
  {
    name: "10-hornostaje",
    title: "Realizacja – Osada Hornostaje",
    description:
      "W Osadzie Hornostaje zainstalowaliśmy 16 paneli Longi Solar o mocy 8kWp.",
    fluentLink:
      "fotowoltaika-osada-hornostaje-bialystok-podlaskie-podlasie-8kwp-panele-longi-solar-16szt",
    mainFile:
      "06-osada-hornostajei-bialystok-podlaskie-podlasie-8kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-8kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 11,
    shortTitle: "8kWp, 16 x Longi Solar",
    place: "Osada Hornostaje, Podlaskie",
  },
  {
    name: "09-monki",
    title: "Realizacja – Mońki",
    description:
      "W Mońkach zainstalowaliśmy również 16 paneli Longi Solar o mocy 7,2kWp.",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlaskie-podlasie-7,2kwp-panele-longi-solar-16szt",
    mainFile:
      "07-monki-bialystok-podlaskie-podlasie-7,2kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-7,2kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 10,
    shortTitle: "7,2kWp, 16 x Longi Solar",
    place: "Mońki, Podlaskie",
  },
  {
    name: "14-monki",
    title: "Realizacja – Mońki",
    description:
      "Kolejna realizacja w Mońkach, tym razem z panelami Jinko Solar o mocy 10kWp.",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlaskie-podlasie-10kwp-panele-jinko-solar12szt",
    mainFile:
      "08-monki-bialystok-podlaskie-podlasie-10kwp-panele-longi-solar12szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-longi-solar12szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 8,
    shortTitle: "10kWp, 12 x Jinko Solar",
    place: "Mońki, Podlaskie",
  },
  {
    name: "15-monki",
    title: "Realizacja – Mońki",
    description:
      "W Mońkach zainstalowaliśmy aż 74 panele JaSolar o imponującej mocy 40kWp",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlaskie-podlasie-40kwp-panele-jasolar-72szt",
    mainFile:
      "09-monki-bialystok-podlaskie-podlasie-40kwp-panele-jasolar-72szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-40kwp-panele-jasolar-72szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 14,
    shortTitle: "40kWp, 74 x JaSolar",
    place: "Mońki, Podlaskie",
  },
  {
    name: "12-monki",
    title: "Realizacja – Mońki",
    description:
      "W Mońkach zainstalowaliśmy 22 panele Longi Solar o mocy 10kWp.",
    fluentLink: "fotowoltaika-monki-podlasie-10kwp-panele-longi-solar22szt",
    mainFile:
      "10-osada-hornostajei-bialystok-podlaskie-podlasie-10kwp-panele-longi-solar22szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-longi-solar22szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 7,
    shortTitle: "10kWp, 22 x Longi Solar",
    place: "Mońki, Podlaskie",
  },
  {
    name: "03-bielsk",
    title: "Realizacja – Bielsk Podlaski",
    description:
      "W Bielsku Podlaskim zainstalowaliśmy 30 paneli Exe Solar o mocy 10kWp, idealnych dla mniejszych obiektów.",
    fluentLink: "fotowoltaika-bielski-podlaski-10kwp-panele-exe-solar-30szt",
    mainFile:
      "11-bielsk-bialystok-podlaskie-podlasie-10kwp-panele-exe-solar-30szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-exe-solar-30szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 15,
    shortTitle: "10kWp, 30 x Exe Solar",
    place: "Bielsk Podlaski, Podlaskie",
  },
  {
    name: "05-bielsk",
    title: "Realizacja – Bielsk Podlaski",
    description:
      "Ta instalacja w Bielsku Podlaskim składa się z 18 paneli Exe Solar o mocy 5,13kWp, idealna dla małych domów.",
    fluentLink:
      "fotowoltaika-bielski-podlaski-podlasie-5,13kwp-panele-Exe-solar-18szt",
    mainFile:
      "12-bielsk-bialystok-podlaskie-podlasie-5,13kwp-panele-Exe-solar-18szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-5,13kwp-panele-Exe-solar-18szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 9,
    shortTitle: "5,13kWp, 18 x Exe Solar",
    place: "Bielsk Podlaski, Podlaskie",
  },
  {
    name: "07-monki",
    title: "Realizacja – Mońki",
    description:
      "Kolejna realizacja w Mońkach z 20 panelami Risen Energy i mocą 10kWp.",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlaskie-10kwp-panele-risen-energy-20szt",
    mainFile:
      "13-monki-bialystok-podlaskie-podlasie-10kwp-panele-risen-energy-20szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-risen-energy-20szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 13,
    shortTitle: "10kWp, 20 x Risen Energy",
    place: "Mońki, Podlaskie",
  },
  {
    name: "13-monki",
    title: "Realizacja – Mońki",
    description:
      "Ta instalacja w Mońkach składa się z 12 paneli Jinko Solar o mocy 6,48kWp.",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlaskie-6kwp-panele-jingo-solar12szt",
    mainFile:
      "14-osada-hornostajei-bialystok-podlaskie-podlasie-10kwp-panele-longi-solar22szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewskI.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-10kwp-panele-longi-solar22szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 6,
    shortTitle: "6,48kWp, 12 x Jinko Solar",
    place: "Mońki, Podlaskie",
  },
  {
    name: "06-monki",
    title: "Realizacja – Mońki",
    description:
      "W Mońkach zainstalowaliśmy 59 paneli Jinko Solar o mocy 32kWp, co zapewnia znaczącą oszczędność energii.",
    fluentLink:
      "fotowoltaika-monki-bialystok-podlasie-32kwp-panele-jinko-solar-59szt",
    mainFile:
      "15-monki-bialystok-podlaskie-podlasie-32kwp-panele-jinko-solar-59szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    fileTemplate:
      "bialystok-podlaskie-podlasie-32kwp-panele-jinko-solar-59szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp",
    itemsCount: 12,
    shortTitle: "32kWp, 59 x Jinko Solar",
    place: "Mońki, Podlaskie",
  },
];
