import s from "./style.module.css";
import { GalleryItem } from "./GalleryItem";

export function ProjectGallery({ title, images, baseLink }) {
  return (
    <>
      <div className="container black">
        <h1>{title}</h1>
        <div className={s.gallery}>
          {images.map((x) => (
            <GalleryItem
              title={x.shortTitle}
              description={x.place}
              image={`${baseLink}${x.mainFile}`}
              onClickItem={() =>
                window.open(`/realizacja/${x.fluentLink}`, "_self")
              }
            />
          ))}
        </div>
      </div>
    </>
  );
}
