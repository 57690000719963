import { WhyUs } from "../../page-components/WhyUs/WhyUs";
import s from "./style.module.css";
import { Certificates, HorizontalCertificates } from "./Certificates";

export function AboutUs() {
  return (
    <>
      <div className="header-container">
        <WhyUs />
      </div>
      <div className="bg-grey">
        <div className="header-container">
          <h2>Zaufaj Ekspertom: Nasze Certyfikaty i Uprawnienia</h2>
          <div className="grid grid-big">
            {Certificates.map((x) => (
              <img
                alt="Certyfikat Odygo Fotowoltaika Białystok"
                className={s.cert}
                src={x.link}
              />
            ))}
          </div>
          <div className={s.horizontalCerts}>
            {HorizontalCertificates.map((x) => (
              <img
                alt="Certyfikat Odygo Fotowoltaika Białystok"
                className={s.horizontalCert}
                src={x.link}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
