import s from "./style.module.css";

export function Thumbnail({
  title,
  subtitle,
  image,
  onClickItem,
  onlyTitle = false,
}) {
  const content = onlyTitle ? (
    <>
      <div className={s.big_text}>{title}</div>
    </>
  ) : (
    <div className={s.text}>
      <h5>{title}</h5>
      <p>{subtitle}</p>
    </div>
  );

  return (
    <>
      <div
        className={`${s.thumbnail} ${onlyTitle ? s.only_title : ""}`}
        onClick={onClickItem}
      >
        <img className={s.img} src={image} alt={title} />
        {content}
      </div>
    </>
  );
}
