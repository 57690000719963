import s from "./style.module.css";
import SuperSlider from "../../components/OpinionList/SuperSlider";
import { useParams } from "react-router-dom";
import { BaseLink, ProjectData } from "../../pages/Projects/ProjectsData";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import { Baner } from "../../components/Baner/Baner";

export function ProjectDetails({ backgroundDirectly, content }) {
  let { projectLink } = useParams();
  let items = [];
  let title = "";
  let description = "";
  if (projectLink != null) {
    const project = ProjectData.find((x) => x.fluentLink === projectLink);
    console.log(project);
    title = project.title;
    description = project.description;
    for (let i = 1; i <= project.itemsCount; i++) {
      let link = `${BaseLink}${project.name}/webp-small/${
        i < 10 ? "0" : ""
      }${i}-${project.fileTemplate}`;
      items.push(<img className={s.img} src={link} alt={project.alt} />);
    }
  }

  return (
    <>
      <Baner baner={backgroundDirectly} />
      <div className="header-container">
        {!content && (
          <>
            <div className="flex">
              <PrimaryButton
                text="Inne realizacje"
                onClick={() => window.open("/realizacje", "_self")}
              ></PrimaryButton>
              <h3>{title}</h3>
              <p className="max-text">{description}</p>
            </div>
            <SuperSlider items={items} enableCenterMode={true} />
          </>
        )}
        {content}
      </div>
    </>
  );
}
