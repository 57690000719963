import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";
import s from "./style.module.css";

export function DotationTemplate({
  title,
  program,
  description,
  background,
  backgroundPosition,
}) {
  const backgroundStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%" ?? backgroundPosition,
  };

  return (
    <>
      <div className={`${s.baner} header-container`}>
        <h1>{title}</h1>
        <PrimaryButton text="Pobierz listę" />
      </div>
      <div style={backgroundStyle} className={s.bg}>
        <div className="header-container">
          <div className={s.content}>
            <div className="large-text">{program}</div>
            <div
              className="description-text"
              style={{ marginBottom: "1.6rem" }}
            >
              {description}
            </div>
            <PrimaryButton text="Dowiedz się więcej" />
          </div>
        </div>
      </div>
    </>
  );
}
