import individual from "../../images/perfect-offer/klient-indywidualny-fotowoltaika-pompy-ciepla-bialystok-odygo.webp";
import farmer from "../../images/perfect-offer/rolnik-fotowoltaika-pompy-ciepla-bialystok-odygo.webp";
import company from "../../images/perfect-offer/firma-fotowoltaika-pompy-ciepla-bialystok-odygo.webp";

export const data = [
  {
    id: "IND",
    headline: "Dla klienta indywidualnego",
    offer: "do domu",
    image: individual,
    alt: "Fotowoltaika Białystok klient indywidualny",
    special: {
      title: "Jesteś klientem indywidualnym?",
      content: (
        <>
          Posiadasz dom i szukasz sposobu na uniezależnienie się od stale
          rosnących cen energii? Wybierz ekologiczne rozwiązanie, zainstaluj na
          swoim dachu instalację fotowoltaiczną i{" "}
          <b>zapomnij o rachunkach za prąd!</b>
        </>
      ),
    },
    link: "/klient-indywidualny",
  },
  {
    id: "FAR",
    headline: "Dla rolnika",
    offer: "dla wsi",
    image: farmer,
    alt: "Fotowoltaika Białystok rolnik",
    special: {
      title: "Jesteś rolnikiem?",
      content: (
        <>
          Jesteś rolnikiem i chcesz zaoszczędzić na kosztach energii?
          Wykorzystaj energię słoneczną i zainstaluj na swoim gospodarstwie
          instalację fotowoltaiczną i <b>zapomnij o rachunkach za prąd!</b>
        </>
      ),
    },
    link: "/oferta-dla-rolnikow",
  },
  {
    id: "CMP",
    headline: "Dla firmy",
    offer: "dla firmy",
    image: company,
    alt: "Fotowoltaika Białystok firma",
    special: {
      title: "Prowadzisz firmę?",
      content: (
        <>
          Jesteś właścicielem firmy i chcesz zredukować koszty energii?
          Wykorzystaj energię słoneczną, zainstaluj na swojej firmie instalację
          fotowoltaiczną i <b>zapomnij o rachunkach za prąd!</b>
        </>
      ),
    },
    link: "/oferta-dla-firm",
  },
];
