import header from "../../images/individual-page/szczesliwa-rodzina-dom-panele-fotowoltaiczne-pompy-ciepla-odygo-bialystok-fotowoltaika-klient-indywidualny.webp";
import program1 from "../../images/individual-page/baner-czyste-powietrze-klient-indywidualny-fotowoltaika-pompy-ciepla-dofinansowanie.webp";
import offer1 from "../../images/individual-page/dom-fotowoltaika-panele-dach-odygo-bialystok-big.webp";
import offer2 from "../../images/individual-page/dom-pompy-ciepla-klimatyzacja-odygo-bialystok-big.webp";
import offer3 from "../../images/individual-page/dom-klimatyzacja-szczesliwa-kobieta-chlodno-latem-cieplo-zima-odygo-bialystok-big.webp";
import offer4 from "../../images/individual-page/dom-magazyn-energii-zielone-energia-fotowoltaika-odygo-bialystok-big.webp";
import offer5 from "../../images/individual-page/dom-ladowarka-samochodowa-ladowanie-samochod-elektryczny-zielona-energia-odygo-bialystok-big.webp";

export const INDIVIDUAL_HEADER = {
  title: (
    <>
      Poznaj naszą ofertę{" "}
      <span style={{ whiteSpace: "nowrap" }}>
        dla <span className="special">klientów indywidualnych</span>
      </span>
    </>
  ),
  description: (
    <>
      Nie trać więcej pieniędzy na opłaty za prąd - zainwestuj w swoją
      przyszłość i ciesz się <b>czystym zyskiem</b> dzięki energii słonecznej!
      Skontaktuj się z nami, a nasi specjaliści pomogą Ci w doborze najlepszego
      rozwiązania dla Twojego domu.
    </>
  ),
  background: header,
};

export const INDIVIDUAL_DONTATIONS = {
  title: "Sprawdź dotacje dla domu",
  program: "Czyste Powietrze 5.0",
  description: (
    <>
      Weź dofinansowanie na ocieplenie domu i wymianę starego pieca od 3
      stycznia 2023 r. nowe warunki – weź <b> nawet do 136 200 zł</b>
    </>
  ),
  background: program1,
};

export const INDIVIDUAL_OFFERS = [
  {
    title: "Fotowoltaika dla domu",
    description:
      "Zastanawiasz się, jak obniżyć rachunki za prąd i jednocześnie przyczynić się do ochrony środowiska? Fotowoltaika dla domu to idealne rozwiązanie. Nasze panele słoneczne są efektywne i łatwe w montażu. Oferujemy kompleksową obsługę, od projektu po instalację. Dowiedz się więcej o naszej ofercie i zacznij oszczędzać już dziś! Nie czekaj, zainwestuj w przyszłość i zrównoważony rozwój.",
    background: offer1,
    link: "/oferta/fotowoltaika-dla-domu",
  },
  {
    title: "Pompy ciepła dla domu",
    description:
      "Chcesz zimą cieszyć się ciepłem, a latem chłodem, nie martwiąc się o wysokie koszty? Pompy ciepła to inwestycja, która się zwraca. Nasze systemy są energooszczędne i przyjazne dla środowiska. Oferujemy różne modele, dostosowane do potrzeb każdego domu. Kliknij, aby poznać szczegóły i dowiedzieć się, jak możesz zmodernizować swój dom. Nie przegap okazji na komfort i oszczędności.",
    background: offer2,
    backgroundPosition: "50% 40%",
    link: "/pompy-ciepla",
  },
  {
    title: "Klimatyzacje dla domu",
    description:
      "Upalne dni i noce sprawiają, że trudno Ci się skupić lub odpocząć? Rozważ zainstalowanie klimatyzacji w domu. Nasze systemy są wydajne i ciche, zapewniając komfort przez cały rok. Oferujemy różne modele, od prostych do bardziej zaawansowanych. Dowiedz się więcej i zobacz, jak możesz poprawić jakość życia w swoim domu. Nie czekaj, zadbaj o swój komfort już teraz.",
    background: offer3,
    backgroundPosition: "50% 30%",
    link: "/oferta/klimatyzacje-dla-domu",
  },
  {
    title: "Magazyny energii",
    description:
      "Chcesz magazynować energię słoneczną na później? Odkryj, jak magazyny energii mogą zwiększyć efektywność Twojego domowego systemu fotowoltaicznego. Nasze rozwiązania są nie tylko praktyczne, ale też ekonomiczne. Magazynowanie energii to przyszłość, która zaczyna się już dziś. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz stać się bardziej niezależnym energetycznie.",
    background: offer4,
    backgroundPosition: "50% 20%",
    link: "/oferta/magazyny-energii",
  },
  {
    title: "Ładowarki samochodowe",
    description:
      "Jeździsz samochodem elektrycznym? Zainwestuj w domową ładowarkę i ciesz się niezależnością. Nasze ładowarki są szybkie i bezpieczne, co sprawia, że są idealnym rozwiązaniem dla każdego właściciela samochodu elektrycznego. Oferujemy różne modele, dostosowane do różnych typów samochodów. Kliknij, aby poznać naszą ofertę i dowiedzieć się, jak możesz zyskać na komforcie i oszczędnościach.",
    background: offer5,
    backgroundPosition: "50% 0%",
    link: "/oferta/ladowarki-samochodowe",
  },
];
