import s from "./style.module.css";
import { DROPDOWN_DATA } from "./MenuItemDropdownData";
import { useState } from "react";

export function MenuItemDropdown() {
  const [isClicked, setIsClicked] = useState(false);
  const onClicked = () => {
    setIsClicked(!isClicked);
  };
  const origin = window.location.origin;

  return (
    <>
      <div className={s.container} onClick={onClicked} style={{ order: 2 }}>
        <div className={`${s.menu_item} ${s.main_item}`}>
          {DROPDOWN_DATA.name}
        </div>
        <div className={s.arrow}>{DROPDOWN_DATA.arrow}</div>
        <div className={s.list}>
          {DROPDOWN_DATA.options.map((item) => (
            <a href={`${origin}${item.link}`} className={s.menu_item}>
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
