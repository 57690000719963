import { Article } from "../../../components/Article/Article";
import { EnergyMagazinesData } from "./DataEnergyMagazines";

export function EnergyMagazines() {
  return (
    <>
      <Article {...EnergyMagazinesData} />
    </>
  );
}
