import project1 from "../../images/previous-projects/01-osada-hornostajei-bialystok-podlaskie-podlasie-6kwp-panele-longi-solar-13szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";
import project2 from "../../images/previous-projects/02-monki-bialystok-podlaskie-podlasie-7,2kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";
import project3 from "../../images/previous-projects/03-bielsk-bialystok-podlaskie-podlasie-10kwp-panele-LG-30szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";
import project4 from "../../images/previous-projects/04-osada-hornostajei-bialystok-podlaskie-podlasie-8kwp-panele-longi-solar-16szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";
import project5 from "../../images/previous-projects/05-monki-bialystok-podlaskie-podlasie-40kwp-panele-jasolar-72szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";
import last from "../../images/previous-projects/06_bransk-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp";

export const data = [
  {
    name: "6 kWp, 13 x Longi Solar",
    place: "Osada Hornostaje, Podlaskie",
    image: project1,
    link: "fotowoltaika-osada-hornostaje-bialystok-podlasie-6kwp-panele-longi-solar-13szt",
  },
  {
    name: "7,2 kWp, 16 x Longi Solar",
    place: "Mońki, Podlaskie",
    image: project2,
    link: "fotowoltaika-monki-bialystok-podlaskie-podlasie-7,2kwp-panele-longi-solar-16szt",
  },
  {
    name: "10 kWp, 30 x LG",
    place: "Bielsk Podlaski, Podlaskie",
    image: project3,
    link: "fotowoltaika-bielsk-bialystok-podlaskie-podlasie-10kwp-panele-LG-30szt",
  },
  {
    name: "8 kWp, 16 x Longi Solar",
    place: "Osada Hornostaje, Podlaskie",
    image: project4,
    link: "fotowoltaika-osada-hornostaje-bialystok-podlaskie-podlasie-8kwp-panele-longi-solar-16szt",
  },
  {
    name: "40 kWp, 74 x JaSolar",
    place: "Mońki, Podlaskie",
    image: project5,
    link: "fotowoltaika-monki-bialystok-podlaskie-podlasie-40kwp-panele-jasolar-72szt",
  },
];

export const lastProject = {
  name: "Zobacz inne instalacje",
  image: last,
};
