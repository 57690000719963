import baner from "../../../images/farmer-page/rolnik-fotowoltaika-pole-dzierzawa-ziemi-odygo-bialystok-big.webp";

export const DataLandTenancy = {
  title: "Dzierżawa ziemi",
  banerPosition: "50% 90%",
  baner: baner,
  content: (
    <>
      <h3>Dlaczego dzierżawa ziemi?</h3>
      <p>
        Dzierżawa ziemi pod instalacje fotowoltaiczne to doskonały sposób na
        dodatkowe dochody dla właścicieli gruntów. Jeżeli jesteś właścicielem
        ziemi w okolicach Białegostoku lub na Podlasiu, OdyGO oferuje atrakcyjne
        warunki dzierżawy pod farmy fotowoltaiczne.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Dzierżawa ziemi polega na wynajęciu części gruntu pod instalacje
        fotowoltaiczne. W zamian za udostępnienie ziemi, właściciel otrzymuje
        regularne wpływy finansowe. OdyGO, z siedzibą w Białymstoku, oferuje
        profesjonalne doradztwo i kompleksową realizację projektów dzierżawy
        ziemi na terenie całego Podlasia.
      </p>
      <h3>Nasza oferta dla właścicieli ziemi</h3>
      <p>
        Jeśli jesteś właścicielem ziemi i zainteresowany jesteś dzierżawą, OdyGO
        ma dla Ciebie atrakcyjną ofertę. Oferujemy korzystne warunki finansowe i
        pełne wsparcie w procesie formalno-prawnym. Nasze projekty są
        realizowane z uwzględnieniem najwyższych standardów jakości i zgodności
        z przepisami prawa. Dodatkowo, oferujemy różne opcje finansowania, aby
        każdy właściciel ziemi mógł skorzystać z tej atrakcyjnej formy
        inwestycji.
      </p>
    </>
  ),
};
