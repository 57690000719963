import s from "./style.module.css";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import { Checkbox } from "../Checkbox/Checkbox";
import { useState } from "react";

export function Form() {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [question, setQuestion] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const validate = () => isChecked;
  const onSubmit = async (e) => {
    if (!validate()) {
      alert(
        "Aby wysłać zapytanie należy wyrazić zgodę na przetwarzanie danych osobowych."
      );
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => onSubmit(e)}
        action="https://formsubmit.co/bialystok@odygo.pl"
        method="POST"
        target="_blank"
      >
        <div className={s.form}>
          <input
            className={s.input}
            type="tel"
            name="phone"
            placeholder="Podaj numer telefonu"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          ></input>

          <input
            className={s.input}
            type="text"
            name="name"
            placeholder="Podaj imię"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          ></input>
          <input
            className={s.input}
            type="email"
            name="email"
            placeholder="Podaj adres e-mail"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            required
          ></input>
          <textarea
            className={`${s.input} ${s.rich}`}
            type="text"
            name="question"
            placeholder="Zadaj tu swoje pytanie"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          ></textarea>
          <Checkbox
            isChecked={isChecked}
            onClickCheck={() => setIsChecked(!isChecked)}
            text={
              <>
                Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w
                formularzu kontaktowym przez OdyGo
              </>
            }
          />
          <PrimaryButton
            text="Wyślij zapytanie"
            onClick={(e) => onSubmit(e)}
            isSubmit={true}
          />
        </div>
      </form>
    </>
  );
}
