import { Article } from "../../../components/Article/Article";
import { PhotovoltaicsForFarmerData } from "./PhotovoltaicsData";

export function PhotovoltaicsForFarmer() {
  return (
    <>
      <Article {...PhotovoltaicsForFarmerData} />
    </>
  );
}
