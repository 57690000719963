import { Plus } from "../../components/Plus/Plus";
import s from "./style.module.css";
import { data } from "./data";
import SuperSlider from "../../components/OpinionList/SuperSlider";

export function CompanyNumbers() {
  const items = data.map((item) => (
    <Plus
      image={item.icon}
      number={item.number}
      text={item.text}
      key={item.id}
      bg={item.id}
    />
  ));

  return (
    <>
      <div className={s.flex}>
        <div className={s.title}>
          <h2>OdyGo w liczbach</h2>
        </div>
        <div className={s.container}>
          <div className={s.bg_left}></div>
          {items}
          <div className={s.bg_right}></div>
        </div>
        <div className={s.mobile}>
          <SuperSlider
            items={items}
            enableCenterMode={true}
            absoluteMenu={true}
            whiteCircle={true}
            variableWidth={false}
          />
        </div>
      </div>
    </>
  );
}
