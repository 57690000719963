import logo from "../../images/icons/odygo.svg";

export const LOGO = logo;

export const MENU_ITEMS = [
  {
    name: "Pompy ciepła",
    link: "pompy-ciepla",
  },
  {
    name: "Fotowoltaika",
    link: "fotowoltaika",
  },

  {
    name: "O nas",
    link: "o-nas",
  },
  {
    name: "Realizacje",
    link: "realizacje",
  },
  {
    name: "Kontakt",
    link: "kontakt",
  },
];
