import baner from "../../../images/individual-page/dom-ladowarka-samochodowa-ladowanie-samochod-elektryczny-zielona-energia-odygo-bialystok-big.webp";

export const DataCarCharges = {
  title: "Ładowarki samochodowe ",
  banerPosition: "50% 10%",
  baner: baner,
  content: (
    <>
      <h3>Dlaczego ładowarki samochodowe?</h3>
      <p>
        Elektryczne samochody stają się coraz bardziej popularne, a z nimi
        rośnie zapotrzebowanie na ładowarki domowe. Jeżeli mieszkasz w
        Białymstoku lub na Podlasiu i zastanawiasz się nad zakupem samochodu
        elektrycznego, ładowarka domowa to must-have. OdyGO, jako lider na rynku
        odnawialnych źródeł energii w regionie, oferuje różne modele ładowarek,
        które są zarówno efektywne, jak i energooszczędne.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Ładowarki samochodowe dla domu działają na zasadzie przesyłania prądu z
        sieci elektrycznej do akumulatora w samochodzie. Mogą być one
        zainstalowane w garażu lub na zewnątrz domu i są zazwyczaj kompatybilne
        z różnymi typami samochodów elektrycznych. OdyGO, z siedzibą w
        Białymstoku, oferuje profesjonalne doradztwo i instalację ładowarek
        samochodowych na terenie całego Podlasia.
      </p>
      <h3>Nasza oferta</h3>
      <p>
        Jeśli jesteś zainteresowany ładowarkami samochodowymi i mieszkasz w
        Białymstoku lub okolicach, OdyGO jest dla Ciebie idealnym partnerem.
        Oferujemy szeroką gamę modeli, od prostych ładowarek jednofazowych po
        zaawansowane ładowarki trójfazowe. Nasze produkty są wysokiej jakości i
        objęte są długoterminową gwarancją. Dodatkowo, oferujemy różne opcje
        finansowania, aby każdy mógł sobie pozwolić na tę inwestycję, która z
        pewnością podniesie komfort korzystania z samochodu elektrycznego.
      </p>
    </>
  ),
};

export const DataCarChargesCompany = {
  title: "Ładowarki samochodowe dla Twojej firmy ",
  banerPosition: "50% 10%",
  baner: baner,
  content: (
    <>
      <h3>Dlaczego ładowarki samochodowe dla firm?</h3>
      <p>
        Coraz więcej firm decyduje się na floty samochodów elektrycznych, co
        jest korzystne zarówno z ekologicznego, jak i ekonomicznego punktu
        widzenia. Jeżeli prowadzisz firmę w Białymstoku lub na Podlasiu i
        zastanawiasz się nad elektryfikacją floty, ładowarki samochodowe to
        kluczowy element tej transformacji. OdyGO, jako lider na rynku
        odnawialnych źródeł energii, oferuje różne modele ładowarek, które są
        zarówno efektywne, jak i energooszczędne.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Ładowarki samochodowe dla firm działają na zasadzie przesyłania prądu z
        sieci elektrycznej do akumulatora w samochodzie. Mogą być one
        zainstalowane w różnych lokalizacjach, od parkingów firmowych po stacje
        ładowania. OdyGO, z siedzibą w Białymstoku, oferuje profesjonalne
        doradztwo i instalację ładowarek samochodowych na terenie całego
        Podlasia.
      </p>
      <h3>Nasza oferta dla firm</h3>
      <p>
        Jeśli jesteś przedsiębiorcą i zainteresowany jesteś ładowarkami
        samochodowymi, OdyGO ma dla Ciebie atrakcyjną ofertę. Oferujemy szeroką
        gamę modeli, od prostych ładowarek jednofazowych po zaawansowane
        ładowarki trójfazowe. Nasze produkty są wysokiej jakości i objęte są
        długoterminową gwarancją. Dodatkowo, oferujemy różne opcje finansowania,
        aby każdy przedsiębiorca mógł sobie pozwolić na tę inwestycję.
      </p>
    </>
  ),
};
