import { Article } from "../../components/Article/Article";
import { HeatPumpsHomeData } from "./DataHeatPumps";

export function HeatPumps() {
  return (
    <>
      <Article {...HeatPumpsHomeData} />
    </>
  );
}
