import { ICONS_DATA, ICONS_FOOTER } from "./IconsData";
import "./icons-style.css";

export function Icons(props) {
  const icons = props.type === "menu" ? ICONS_DATA : ICONS_FOOTER;

  return icons.map((item, index) => (
    <div
      className="icon"
      key={index}
      onClick={() =>
        item.isLink ? window.open(item.link, "_blank") : item.onClick()
      }
    >
      {item.image}
    </div>
  ));
}
