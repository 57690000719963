import { Article } from "../../../components/Article/Article";
import { DataCarChargesCompany } from "./DataCarCharges";

export function CompanyCarCharges() {
  return (
    <>
      <Article {...DataCarChargesCompany} />
    </>
  );
}
