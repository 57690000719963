import s from "./style.module.css";

export function GalleryItem({ image, title, description, alt, onClickItem }) {
  const content = (
    <div className={s.text}>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );

  return (
    <>
      <div className={`${s.galleryItem}`} onClick={onClickItem}>
        <img className={s.img} src={image} alt={alt} />
        {content}
      </div>
    </>
  );
}
