import { TemplateHeader } from "../../page-components/TemplateHeader/TemplateHeader";
import { DotationTemplate } from "../../page-components/DotationTemplate/DotationTemplate";
import { TemplateOffer } from "../../page-components/TemplateOffer/TemplateOffer";
import {
  INDIVIDUAL_HEADER,
  INDIVIDUAL_DONTATIONS,
  INDIVIDUAL_OFFERS,
} from "./Data";

export function IndividualClient() {
  const header = (
    <TemplateHeader
      title={INDIVIDUAL_HEADER.title}
      subtitle={INDIVIDUAL_HEADER.description}
      backgroundUrl={INDIVIDUAL_HEADER.background}
    />
  );

  const dontation = (
    <DotationTemplate
      title={INDIVIDUAL_DONTATIONS.title}
      program={INDIVIDUAL_DONTATIONS.program}
      description={INDIVIDUAL_DONTATIONS.description}
      background={INDIVIDUAL_DONTATIONS.background}
    />
  );

  const offers = INDIVIDUAL_OFFERS.map((x) => (
    <TemplateOffer
      background={x.background}
      title={x.title}
      description={x.description}
      backgroundPosition={x.backgroundPosition}
      link={x.link}
    />
  ));

  return (
    <>
      <header>{header}</header>
      <main>
        {dontation}
        {offers}
      </main>
    </>
  );
}
