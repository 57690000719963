import homeBaner from "../../../images/individual-page/dom-klimatyzacja-szczesliwa-kobieta-chlodno-latem-cieplo-zima-odygo-bialystok-big.webp";
import farmerBaner from "../../../images/farmer-page/rolnik-klimatyzacja-farma-rolnictwo-odygo-bialystok-big.webp";
import companyBaner from "../../../images/company-page/firma-fotowoltaika-klimatyzacja-pompa-ciepla-odygo-bialystok-big.webp";

export const AirConditioningHomeData = {
  title: "Klimatyzacja dla Twojego domu",
  banerPosition: "50% 7%",
  baner: homeBaner,
  content: (
    <>
      <h3>Dlaczego klimatyzacja?</h3>
      <p>
        Klimatyzacja w domu to nie tylko luksus, ale coraz częściej konieczność,
        zwłaszcza w gorące, letnie dni. Dzięki klimatyzacji możesz cieszyć się
        komfortową temperaturą w domu przez cały rok. Jeżeli mieszkasz w
        Białymstoku lub na Podlasiu, gdzie lata bywają upalne, a zimy chłodne,
        klimatyzacja może znacząco poprawić jakość Twojego życia. OdyGO, jako
        lider na rynku odnawialnych źródeł energii w Białymstoku i na Podlasiu,
        oferuje różne systemy klimatyzacyjne, które są zarówno efektywne, jak i
        energooszczędne.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Klimatyzatory działają na zasadzie przemieszczania ciepła z wnętrza do
        zewnątrz, w przypadku chłodzenia, oraz w odwrotną stronę, w przypadku
        ogrzewania. Nowoczesne jednostki są wysoce efektywne i mogą być
        sterowane zdalnie, co daje duże możliwości w zarządzaniu komfortem
        termicznym w domu. OdyGO, z siedzibą w Białymstoku, oferuje
        profesjonalne doradztwo i instalację systemów klimatyzacyjnych na
        terenie całego Podlasia.
      </p>
      <h3>Nasza oferta dla domu</h3>
      <p>
        Jeśli jesteś zainteresowany klimatyzacją i mieszkasz w Białymstoku lub
        okolicach, OdyGO jest dla Ciebie idealnym partnerem. Oferujemy szeroką
        gamę modeli, od prostych jednostek ściennej po zaawansowane systemy
        klimatyzacyjne. Nasze produkty są wysokiej jakości i objęte są
        długoterminową gwarancją. Dodatkowo, oferujemy różne opcje finansowania,
        aby każdy mógł sobie pozwolić na tę inwestycję, która z pewnością
        podniesie komfort życia w domu.
      </p>
    </>
  ),
};

export const AirConditioningFarmerData = {
  title: "Klimatyzacja dla Twojego gospodarstwa",
  banerPosition: "50% 40%",
  baner: farmerBaner,
  content: (
    <>
      <h3>Dlaczego klimatyzacja dla wsi?</h3>
      <p>
        Klimatyzacja to nie tylko komfort w domu, ale również w budynkach
        gospodarczych i innych pomieszczeniach na wsi. W upalne dni może to być
        kluczowe dla utrzymania odpowiednich warunków dla zwierząt i
        przechowywania produktów. Jeżeli jesteś rolnikiem w okolicach
        Białegostoku lub na Podlasiu, OdyGO oferuje specjalne rozwiązania
        klimatyzacyjne dostosowane do potrzeb rolnictwa.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Klimatyzatory działają na zasadzie przemieszczania ciepła, podobnie jak
        w przypadku klimatyzacji dla domu. Mogą być one zainstalowane w różnych
        budynkach na terenie gospodarstwa rolnego. OdyGO, z siedzibą w
        Białymstoku, oferuje profesjonalne doradztwo i instalację systemów
        klimatyzacyjnych na terenie całego Podlasia, z uwzględnieniem specyfiki
        rolnictwa i potrzeb rolników.
      </p>
      <h3>Nasza oferta dla domu</h3>
      <p>
        Jeśli jesteś rolnikiem i zainteresowany jesteś klimatyzacją, OdyGO ma
        dla Ciebie atrakcyjną ofertę. Oferujemy różne modele klimatyzatorów,
        które są idealnie dostosowane do potrzeb rolnictwa. Nasze produkty są
        wysokiej jakości i objęte są długoterminową gwarancją. Dodatkowo,
        oferujemy różne opcje finansowania, aby każdy rolnik mógł sobie pozwolić
        na tę inwestycję, która z pewnością podniesie komfort pracy na
        gospodarstwie.
      </p>
    </>
  ),
};

export const AirConditioningForCompanyData = {
  title: "Klimatyzacja dla Twojej firmy",
  baner: companyBaner,
  content: (
    <>
      <h3>Dlaczego Klimatyzacja dla firm?</h3>
      <p>
        Klimatyzacja w miejscu pracy to nie tylko kwestia komfortu, ale również
        efektywności. Dobrze zaprojektowany system klimatyzacyjny może znacząco
        wpłynąć na produktywność pracowników i jakość przechowywanych produktów
        czy materiałów. Jeżeli prowadzisz firmę w Białymstoku lub na Podlasiu,
        OdyGO oferuje profesjonalne rozwiązania klimatyzacyjne dostosowane do
        potrzeb przedsiębiorców.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Systemy klimatyzacyjne dla firm działają na zasadzie przemieszczania
        ciepła, podobnie jak w przypadku klimatyzacji dla domu. Mogą być one
        zainstalowane w różnych budynkach firmowych, od biur po magazyny. OdyGO,
        z siedzibą w Białymstoku, oferuje profesjonalne doradztwo i instalację
        systemów klimatyzacyjnych na terenie całego Podlasia.
      </p>
      <h3>Nasza oferta dla firm</h3>
      <p>
        Jeśli prowadzisz firmę i jesteś zainteresowany klimatyzacją, OdyGO ma
        dla Ciebie atrakcyjną ofertę. Oferujemy różne modele klimatyzatorów,
        które są idealnie dostosowane do potrzeb przedsiębiorstw. Nasze produkty
        są wysokiej jakości i objęte są długoterminową gwarancją. Dodatkowo,
        oferujemy różne opcje finansowania, aby każdy przedsiębiorca mógł sobie
        pozwolić na tę inwestycję, która z pewnością podniesie komfort i
        efektywność pracy.
      </p>
    </>
  ),
};
