import { Article } from "../../../components/Article/Article";
import { DataCarCharges } from "./DataCarCharges";

export function CarCharges() {
  return (
    <>
      <Article {...DataCarCharges} />
    </>
  );
}
