import "./icons-style.css";

const facebook = {
  name: "facebook",
  isLink: true,
  link: "https://www.facebook.com/profile.php?id=100069756367962",
  image: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16.0401C32 7.18596 24.832 0 16 0C7.168 0 0 7.18596 0 16.0401C0 23.8035 5.504 30.2677 12.8 31.7594V20.8521H9.6V16.0401H12.8V12.0301C12.8 8.93434 15.312 6.41604 18.4 6.41604H22.4V11.2281H19.2C18.32 11.2281 17.6 11.9499 17.6 12.8321V16.0401H22.4V20.8521H17.6V32C25.68 31.198 32 24.3649 32 16.0401Z"
        fill="url(#paint0_linear_2507_2958)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2507_2958"
          x1="1.72161"
          y1="4.68497"
          x2="29.9632"
          y2="18.8183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const facebook2 = {
  name: "facebook",
  isLink: true,
  link: "https://www.facebook.com/profile.php?id=100069756367962",
  image: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16.0401C32 7.18596 24.832 0 16 0C7.168 0 0 7.18596 0 16.0401C0 23.8035 5.504 30.2677 12.8 31.7594V20.8521H9.6V16.0401H12.8V12.0301C12.8 8.93434 15.312 6.41604 18.4 6.41604H22.4V11.2281H19.2C18.32 11.2281 17.6 11.9499 17.6 12.8321V16.0401H22.4V20.8521H17.6V32C25.68 31.198 32 24.3649 32 16.0401Z"
        fill="url(#paint0_linear_2507_2959)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2507_2959"
          x1="1.72161"
          y1="4.68497"
          x2="29.9632"
          y2="18.8183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const instagram = {
  name: "instagram",
  isLink: true,
  link: "https://www.instagram.com/odygo_oze",
  image: (
    <svg
      className="icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM27 11.38C27 7.86 24.14 5 20.62 5H11.38C9.68792 5 8.06514 5.67218 6.86866 6.86866C5.67218 8.06514 5 9.68792 5 11.38V20.62C5 24.14 7.86 27 11.38 27H20.62C22.3121 27 23.9349 26.3278 25.1313 25.1313C26.3278 23.9349 27 22.3121 27 20.62V11.38ZM8.35986 8.35986C9.1025 7.61721 10.1097 7.2 11.16 7.2H20.84C23.029 7.2 24.8 8.971 24.8 11.16V20.84C24.8 21.8903 24.3828 22.8975 23.6401 23.6401C22.8975 24.3828 21.8903 24.8 20.84 24.8H11.16C8.971 24.8 7.2 23.029 7.2 20.84V11.16C7.2 10.1097 7.61721 9.1025 8.35986 8.35986ZM22.7473 9.25273C22.4894 8.99487 22.1397 8.85 21.775 8.85C21.4103 8.85 21.0606 8.99487 20.8027 9.25273C20.5449 9.51059 20.4 9.86033 20.4 10.225C20.4 10.5897 20.5449 10.9394 20.8027 11.1973C21.0606 11.4551 21.4103 11.6 21.775 11.6C22.1397 11.6 22.4894 11.4551 22.7473 11.1973C23.0051 10.9394 23.15 10.5897 23.15 10.225C23.15 9.86033 23.0051 9.51059 22.7473 9.25273ZM19.8891 12.1109C18.8576 11.0795 17.4587 10.5 16 10.5C14.5413 10.5 13.1424 11.0795 12.1109 12.1109C11.0795 13.1424 10.5 14.5413 10.5 16C10.5 17.4587 11.0795 18.8576 12.1109 19.8891C13.1424 20.9205 14.5413 21.5 16 21.5C17.4587 21.5 18.8576 20.9205 19.8891 19.8891C20.9205 18.8576 21.5 17.4587 21.5 16C21.5 14.5413 20.9205 13.1424 19.8891 12.1109ZM13.6665 13.6665C14.2854 13.0477 15.1248 12.7 16 12.7C16.8752 12.7 17.7146 13.0477 18.3335 13.6665C18.9523 14.2854 19.3 15.1248 19.3 16C19.3 16.8752 18.9523 17.7146 18.3335 18.3335C17.7146 18.9523 16.8752 19.3 16 19.3C15.1248 19.3 14.2854 18.9523 13.6665 18.3335C13.0477 17.7146 12.7 16.8752 12.7 16C12.7 15.1248 13.0477 14.2854 13.6665 13.6665Z"
        fill="url(#paint0_linear_2666_73)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2666_73"
          x1="1.72161"
          y1="4.68497"
          x2="29.9632"
          y2="18.8183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const instagram2 = {
  name: "instagram",
  isLink: true,
  link: "https://www.instagram.com/odygo_oze",
  image: (
    <svg
      className="icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM27 11.38C27 7.86 24.14 5 20.62 5H11.38C9.68792 5 8.06514 5.67218 6.86866 6.86866C5.67218 8.06514 5 9.68792 5 11.38V20.62C5 24.14 7.86 27 11.38 27H20.62C22.3121 27 23.9349 26.3278 25.1313 25.1313C26.3278 23.9349 27 22.3121 27 20.62V11.38ZM8.35986 8.35986C9.1025 7.61721 10.1097 7.2 11.16 7.2H20.84C23.029 7.2 24.8 8.971 24.8 11.16V20.84C24.8 21.8903 24.3828 22.8975 23.6401 23.6401C22.8975 24.3828 21.8903 24.8 20.84 24.8H11.16C8.971 24.8 7.2 23.029 7.2 20.84V11.16C7.2 10.1097 7.61721 9.1025 8.35986 8.35986ZM22.7473 9.25273C22.4894 8.99487 22.1397 8.85 21.775 8.85C21.4103 8.85 21.0606 8.99487 20.8027 9.25273C20.5449 9.51059 20.4 9.86033 20.4 10.225C20.4 10.5897 20.5449 10.9394 20.8027 11.1973C21.0606 11.4551 21.4103 11.6 21.775 11.6C22.1397 11.6 22.4894 11.4551 22.7473 11.1973C23.0051 10.9394 23.15 10.5897 23.15 10.225C23.15 9.86033 23.0051 9.51059 22.7473 9.25273ZM19.8891 12.1109C18.8576 11.0795 17.4587 10.5 16 10.5C14.5413 10.5 13.1424 11.0795 12.1109 12.1109C11.0795 13.1424 10.5 14.5413 10.5 16C10.5 17.4587 11.0795 18.8576 12.1109 19.8891C13.1424 20.9205 14.5413 21.5 16 21.5C17.4587 21.5 18.8576 20.9205 19.8891 19.8891C20.9205 18.8576 21.5 17.4587 21.5 16C21.5 14.5413 20.9205 13.1424 19.8891 12.1109ZM13.6665 13.6665C14.2854 13.0477 15.1248 12.7 16 12.7C16.8752 12.7 17.7146 13.0477 18.3335 13.6665C18.9523 14.2854 19.3 15.1248 19.3 16C19.3 16.8752 18.9523 17.7146 18.3335 18.3335C17.7146 18.9523 16.8752 19.3 16 19.3C15.1248 19.3 14.2854 18.9523 13.6665 18.3335C13.0477 17.7146 12.7 16.8752 12.7 16C12.7 15.1248 13.0477 14.2854 13.6665 13.6665Z"
        fill="url(#paint0_linear_2666_734)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2666_734"
          x1="1.72161"
          y1="4.68497"
          x2="29.9632"
          y2="18.8183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const up = {
  name: "up",
  isLink: false,
  onClick: () => window.scrollTo(0, 0),
  image: (
    <svg
      className="icon up"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C18.2133 -1.93496e-07 20.2933 0.420267 22.24 1.2608C24.1867 2.10027 25.88 3.24 27.32 4.68C28.76 6.12 29.8997 7.81333 30.7392 9.76C31.5797 11.7067 32 13.7867 32 16C32 18.2133 31.5797 20.2933 30.7392 22.24C29.8997 24.1867 28.76 25.88 27.32 27.32C25.88 28.76 24.1867 29.9003 22.24 30.7408C20.2933 31.5803 18.2133 32 16 32C13.7867 32 11.7067 31.5803 9.76 30.7408C7.81334 29.9003 6.12 28.76 4.68 27.32C3.24 25.88 2.10027 24.1867 1.2608 22.24C0.420266 20.2933 0 18.2133 0 16C0 13.7867 0.420265 11.7067 1.2608 9.76C2.10027 7.81334 3.24 6.12 4.68 4.68C6.12 3.24 7.81333 2.10027 9.76 1.2608C11.7067 0.420267 13.7867 1.93496e-07 16 0ZM16 11L10 18L22 18L16 11Z"
        fill="url(#paint0_linear_2815_888)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2815_888"
          x1="1.72161"
          y1="4.68497"
          x2="29.9632"
          y2="18.8183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

export const ICONS_DATA = [facebook, instagram];

export const ICONS_FOOTER = [facebook2, instagram2, up];

export const PHONE = {
  name: "phone",
  isLink: false,
  image: (
    <svg
      className="icon phone"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="40" fill="url(#paint0_linear_2722_1243)" />
      <path
        d="M39.58 44.42L33.04 50.96C32.32 50.32 31.62 49.66 30.94 48.98C28.9175 46.944 27.0522 44.7578 25.36 42.44C23.72 40.16 22.4 37.88 21.44 35.62C20.48 33.34 20 31.16 20 29.08C20 27.72 20.24 26.42 20.72 25.22C21.2 24 21.96 22.88 23.02 21.88C24.3 20.62 25.7 20 27.18 20C27.74 20 28.3 20.12 28.8 20.36C29.32 20.6 29.78 20.96 30.14 21.48L34.78 28.02C35.14 28.52 35.4 28.98 35.58 29.42C35.76 29.84 35.86 30.26 35.86 30.64C35.86 31.12 35.72 31.6 35.44 32.06C35.18 32.52 34.8 33 34.32 33.48L32.8 35.06C32.58 35.28 32.48 35.54 32.48 35.86C32.48 36.02 32.5 36.16 32.54 36.32C32.6 36.48 32.66 36.6 32.7 36.72C33.06 37.38 33.68 38.24 34.56 39.28C35.46 40.32 36.42 41.38 37.46 42.44C38.18 43.14 38.88 43.82 39.58 44.42Z"
        fill="white"
      />
      <path
        d="M59.9402 52.66C59.9375 53.4147 59.7667 54.1595 59.4402 54.84C59.1002 55.56 58.6602 56.24 58.0802 56.88C57.1002 57.96 56.0202 58.74 54.8002 59.24C54.7802 59.24 54.7602 59.26 54.7402 59.26C53.5602 59.74 52.2802 60 50.9002 60C48.8602 60 46.6802 59.52 44.3802 58.54C42.0802 57.56 39.7802 56.24 37.5002 54.58C36.7202 54 35.9402 53.42 35.2002 52.8L41.7402 46.26C42.3002 46.68 42.8002 47 43.2202 47.22C43.3202 47.26 43.4402 47.32 43.5802 47.38C43.7402 47.44 43.9002 47.46 44.0802 47.46C44.4202 47.46 44.6802 47.34 44.9002 47.12L46.4202 45.62C46.9202 45.12 47.4002 44.74 47.8602 44.5C48.3202 44.22 48.7802 44.08 49.2802 44.08C49.6602 44.08 50.0602 44.16 50.5002 44.34C50.9402 44.52 51.4002 44.78 51.9002 45.12L58.5202 49.82C59.0402 50.18 59.4002 50.6 59.6202 51.1C59.8202 51.6 59.9402 52.1 59.9402 52.66Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2722_1243"
          x1="4.30402"
          y1="11.7124"
          x2="74.908"
          y2="47.0457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73964" />
          <stop offset="1" stopColor="#F6A632" />
        </linearGradient>
      </defs>
    </svg>
  ),
};
