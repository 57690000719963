import baner from "../../../images/individual-page/dom-magazyn-energii-zielone-energia-fotowoltaika-odygo-bialystok-big.webp";

export const EnergyMagazinesData = {
  title: "Magazyny energii",
  baner: baner,
  content: (
    <>
      <h3>Dlaczego magazyny energii?</h3>
      <p>
        Magazyny energii to idealne rozwiązanie dla osób, które chcą maksymalnie
        wykorzystać potencjał swoich paneli fotowoltaicznych. Dzięki magazynom
        energii możesz przechowywać nadwyżki energii na później, co pozwala na
        jej wykorzystanie nawet wtedy, gdy panele nie generują prądu. Jeżeli
        mieszkasz w Białymstoku lub na Podlasiu i jesteś zainteresowany
        odnawialnymi źródłami energii, OdyGO ma dla Ciebie atrakcyjną ofertę.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Magazyny energii przechowują nadwyżki energii elektrycznej w specjalnych
        akumulatorach. Te akumulatory mogą być później używane, gdy jest
        zapotrzebowanie na energię, ale panele fotowoltaiczne nie są w stanie
        jej dostarczyć. OdyGO, z siedzibą w Białymstoku, oferuje różne rodzaje
        magazynów energii, dostosowane do indywidualnych potrzeb każdego klienta
        na Podlasiu.
      </p>
      <h3>Nasza oferta</h3>
      <p>
        Jeśli jesteś zainteresowany magazynami energii i mieszkasz w Białymstoku
        lub okolicach, OdyGO jest dla Ciebie idealnym partnerem. Oferujemy różne
        modele magazynów energii, które są kompatybilne z różnymi systemami
        fotowoltaicznymi. Nasze produkty są wysokiej jakości i objęte są
        długoterminową gwarancją. Dodatkowo, oferujemy różne opcje finansowania,
        aby każdy mógł sobie pozwolić na tę inwestycję, która z pewnością
        zwiększy efektywność Twojego systemu fotowoltaicznego.
      </p>
    </>
  ),
};
