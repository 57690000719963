import baner from "../../../images/company-page/firma-fotowoltaika-infrastruktura-dofinansowanie-odygo-bialystok.webp";

export const DataPhotovoltaicsFarms = {
  title: "Farmy fotowoltaiczne",
  //   banerPosition: "50% 7%",
  baner: baner,
  content: (
    <>
      <h3>Farmy fotowoltaiczne</h3>
      <p>
        Farmy fotowoltaiczne to duże instalacje słoneczne, które generują
        znaczące ilości energii. Są one idealnym rozwiązaniem dla firm i
        inwestorów zainteresowanych produkcją energii na dużą skalę. Jeżeli
        jesteś przedsiębiorcą lub inwestorem w Białymstoku lub na Podlasiu,
        OdyGO oferuje kompleksowe rozwiązania w zakresie budowy i eksploatacji
        farm fotowoltaicznych.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Farmy fotowoltaiczne składają się z dużych ilości paneli słonecznych,
        które są zainstalowane na specjalnie przygotowanym terenie. OdyGO, z
        siedzibą w Białymstoku, oferuje profesjonalne doradztwo i realizację
        projektów farm fotowoltaicznych na terenie całego Podlasia, od fazy
        projektowej po eksploatację.
      </p>
      <h3>Nasza oferta dla inwestorów i firm</h3>
      <p>
        Jeśli jesteś inwestorem lub przedsiębiorcą i zainteresowany jesteś
        budową farmy fotowoltaicznej, OdyGO ma dla Ciebie atrakcyjną ofertę.
        Oferujemy kompleksowe usługi, od analizy lokalizacji po instalację i
        utrzymanie farmy. Nasze projekty są realizowane z uwzględnieniem
        najwyższych standardów jakości i zgodności z przepisami prawa.
        Dodatkowo, oferujemy różne opcje finansowania, aby każdy inwestor mógł
        skorzystać z tej atrakcyjnej formy inwestycji.
      </p>
    </>
  ),
};
