import s from "./style.module.css";
import man from "../../images/why-us/profesjonalna-firma-montaz-fotowoltaika-dach-zielona-energia-tani-prad-pompa-ciepla-bialystok-odygo.webp";
import family1 from "../../images/why-us/zadowoleni-ludzie-bo-maja-fotowoltaika-od-odygo-bialystok-zadowolony-klient-indywidualny-pompa-ciepla.webp";
import family2 from "../../images/why-us/zadowolony-businessman-fotowoltaika-odygo-bialystok-klient-firma-pompa-ciepla.webp";
import { BottomButton } from "../../components/BottomButton/BottomButton";

export function WhyUs() {
  return (
    <>
      {/* <div className="header-container"> */}
      <div className="container">
        <h2>Dlaczego warto wybrać OdyGo?</h2>
        <div className={s.content}>
          <img
            className={s.image}
            src={man}
            alt="Zadowoleni ludzie Pompa ciepła i Fotowoltaika od Odygo Białystok Zadowolony Klient Indywidualny"
          />
          <div className={s.text}>
            <h3>Lokalna firma z wartościami!</h3>
            <p>
              OdyGo to lokalna firma specjalizująca się w montowaniu{" "}
              <b>instalacji fotowoltaicznych, pomp ciepła i klimatyzacji. </b>
              Działamy na rynku odnawialnych źródeł energii od 2017 roku i od
              tamtego czasu skupiamy się na kompleksowej obsłudze klientów,
              zwracając szczególną uwagę na ich potrzeby.
            </p>
            <p>
              W naszej ofercie znajdują się projekty fotowoltaiczne, pompy
              ciepła i klimatyzacje. Od 2020 roku oferujemy również{" "}
              <b>instalacje pomp ciepła i wiatraków</b>, co pozwala nam na
              jeszcze szerszą obsługę klientów i zwiększenie naszych możliwości
              w branży.
            </p>
          </div>
        </div>
        <div className={s.content + " " + s.reverse_column}>
          <div className={s.text}>
            <h3>Setki zadowolonych klientów!</h3>
            <p>
              W ciągu tych kilku lat na rynku wykonaliśmy setki instalacji
              fotowoltaicznych i cieszymy się{" "}
              <b>setkami zadowolonych klientów</b> z województwa podlaskiego i
              nie tylko. Nasza firma ma szeroko rozwinięty układ moralny, który
              wpływa na jakość naszych usług i podejście do klienta.
            </p>
            <p>
              Jeśli szukasz lokalnej firmy, która pomoże Ci w przejściu na
              odnawialne źródła energii, to OdyGo jest idealnym wyborem dla
              Ciebie. Mieścimy się w <b>Białymstoku</b>, ale działamy na terenie{" "}
              <b>całej Polski</b>. Skontaktuj się z nami już dziś i przekonaj
              się, jak możemy pomóc w realizacji Twoich potrzeb.
            </p>
            <BottomButton text="Wyślij zapytanie" />
          </div>
          <div className={s.images_container}>
            <img
              className={s.split_image}
              src={family1}
              alt="Rodzina zadowolona bo ma Pompa ciepła i Fotowoltaika od Odygo Białystok"
            />
            <img
              className={s.split_image}
              src={family2}
              alt="Zadowolony businessman Fotowoltaika Białystok Odygo Klient Firma Pompy ciepła"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
