import { PreviousProjects } from "../../page-components/PreviousProjects/PreviousProjects";
import { Baner } from "../Baner/Baner";

export function Article({ title, content, baner, banerPosition }) {
  const whyUs = (
    <>
      <h3>Dlaczego warto wybrać OdyGO?</h3>
      <p className="max-text">
        Wybierając OdyGO, wybierasz firmę z wieloletnim doświadczeniem i szeroką
        wiedzą na temat odnawialnych źródeł energii. Jesteśmy lokalnym liderem
        na rynku odnawialnych źródeł energii, z siedzibą w Białymstoku i
        działającym na terenie całego Podlasia. Nasza oferta jest kompleksowa i
        dostosowana do indywidualnych potrzeb każdego klienta, niezależnie od
        tego, czy jesteś właścicielem domu, rolnikiem, czy przedsiębiorcą.
        Wysoka jakość naszych usług i produktów jest potwierdzona licznymi
        certyfikatami i pozytywnymi opiniami klientów. Dzięki nam, przejście na
        odnawialne źródła energii jest prostsze niż kiedykolwiek.
      </p>
    </>
  );

  return (
    <>
      <Baner baner={baner} banerPosition={banerPosition} />
      <div className="header-container">
        <div className="content-container">
          <h2>{title}</h2>
          {content}
          {whyUs}
        </div>
      </div>
      <div className="header-bg">
        <div className="header-container">
          <PreviousProjects />
        </div>
      </div>
      <div className="header-container">
        <div className="content-container">
          <h2>Skontaktuj się z nami</h2>
          <p className="max-text">
            Jeśli jesteś zainteresowany fotowoltaiką dla swojego domu,
            skontaktuj się z nami już dziś. Nasz zespół ekspertów jest gotów,
            aby pomóc Ci w realizacji Twoich potrzeb związanych z odnawialnymi
            źródłami energii.
          </p>
        </div>
      </div>
    </>
  );
}
