export const Certificates = [
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/01-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },

  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/03-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/04-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/02-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/06-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/08-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
];

export const HorizontalCertificates = [
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/07-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
  {
    link: "https://test.odygo.pl/o-nas/certyfikaty/05-certyfikat-odygo-profesjonalna-firma-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-zielona-czysta-bialystok-podlaskie-podlasie-monki.webp",
  },
];
