import homeBaner from "../../images/individual-page/dom-pompy-ciepla-klimatyzacja-odygo-bialystok-big.webp";

export const HeatPumpsHomeData = {
  title: "Pompy ciepła dla Twojego domu",
  banerPosition: "50% 7%",
  baner: homeBaner,
  content: (
    <>
      <h3>Dlaczego pompy ciepła?</h3>
      <p>
        Pompy ciepła to nowoczesne i efektywne rozwiązanie do ogrzewania i
        chłodzenia domu. Dzięki nim możesz znacząco obniżyć rachunki za energię,
        jednocześnie korzystając z ekologicznego i odnawialnego źródła ciepła.
        Pompy ciepła są również doskonałym wyborem dla osób mieszkających w
        Białymstoku i na Podlasiu, gdzie zimy bywają surowe, a lata gorące.
        OdyGO, jako lider na rynku odnawialnych źródeł energii w regionie,
        oferuje różnorodne modele pomp ciepła, dostosowane do indywidualnych
        potrzeb i możliwości finansowych.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Pompy ciepła działają na zasadzie przemieszczania ciepła z jednego
        miejsca do drugiego. Mogą czerpać ciepło z powietrza, wody czy ziemi i
        przekazywać je do wnętrza domu. Dzięki temu są niezwykle efektywne
        energetycznie i ekologiczne. OdyGO, z siedzibą w Białymstoku, oferuje
        profesjonalne doradztwo i instalację pomp ciepła na terenie całego
        Podlasia. Nasze systemy są nie tylko wydajne, ale również łatwe w
        obsłudze i utrzymaniu.
      </p>
      <h3>Nasza oferta dla domu</h3>
      <p>
        Jeśli jesteś zainteresowany pompami ciepła i mieszkasz w Białymstoku lub
        okolicach, OdyGO jest dla Ciebie idealnym partnerem. Oferujemy szeroką
        gamę modeli, od prostych pomp powietrznych po zaawansowane systemy
        geotermalne. Nasze produkty są wysokiej jakości i objęte są
        długoterminową gwarancją. Dodatkowo, oferujemy różne opcje finansowania,
        aby każdy mógł sobie pozwolić na tę ekologiczną inwestycję.
      </p>
    </>
  ),
};
