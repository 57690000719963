import avatar1 from "../../images/company-opinions/avatar icon-01.svg";
import avatar2 from "../../images/company-opinions/avatar icon-02.svg";
import avatar3 from "../../images/company-opinions/avatar icon-03.svg";
import avatar6 from "../../images/company-opinions/avatar icon-06.svg";
import avatar7 from "../../images/company-opinions/avatar icon-07.svg";
import avatar8 from "../../images/company-opinions/avatar icon-08.svg";

export const data = [
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSURoaDdmTXRRRRAB!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgIDhh7fMtQE%7CCgwI4f_aoAYQyISn2QI%7C?hl=pl",
    user: {
      name: "Joanna",
      link: "https://www.google.com/maps/contrib/106364172580366349401?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegQIARAZ",
      thumbnail: avatar2,
      reviews: 1,
    },
    rating: 5.0,
    date: "2 tygodnie temu",
    snippet:
      "Polecam! Pan Maciej jako jedyny podjął się uzyskania pozwolenia od Konserwatora zabytków. Inne firmy nie chciały się zmagać z biurokracją, a Pan Maciek temu podołał z dużym zaangażowanie. Montaż instalacji bez problemu.",
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNocTRLY1RREAE!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgIChq4KcTQ%7CCgwIu_7XnwYQ-NK5lAE%7C?hl=pl",
    user: {
      name: "Paweł",
      link: "https://www.google.com/maps/contrib/106183670693000493825?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegQIARAw",
      thumbnail: avatar3,
      local_guide: true,
      reviews: 21,
    },
    rating: 5.0,
    date: "miesiąc temu",
    snippet:
      "Korzystając z usług firmy OdyGo, byłem mile zaskoczony ich profesjonalnym podejściem do instalacji paneli fotowoltaicznych na moim dachu. Właściciel, Pan Maciej, był bardzo pomocny i doradził mi, które rozwiązanie będzie dla mnie najlepsze. Cały proces od początku do końca przebiegł sprawnie i w czasie, a finalny efekt przeszedł moje oczekiwania. Z czystym sumieniem polecam OdyGo",
    response: {
      date: "2 tygodnie temu",
      snippet:
        "Dziękujemy Panie Pawle. Z wielką przyjemnością wykonaliśmy u Pana instalacje. Firma ODYGO jest stworzona właśnie dla takich klientów jak Pan.",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNoazVTcDhnRRAB!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgIChk5Sp8gE%7CCgsIu6jSnwYQoJmMUA%7C?hl=pl",
    user: {
      name: "hant118",
      link: "https://www.google.com/maps/contrib/109292674763014793199?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegQIARBG",
      thumbnail: avatar1,
      reviews: 1,
    },
    rating: 5.0,
    date: "miesiąc temu",
    snippet:
      "Firma godna polecenia. W 2021r. założyli mi instalację fotowoltaiczną. Sama instalacja w jeden dzień, bardzo sprawnie. Właściciel, Pan Maciek, był bardzo pomocny na każdym etapie inwestycji , a i później nie ma problemu z odbieraniem telefonu i odpowiadaniu na pytania odnośnie instalacji.",
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURoa1BxVUNBEAE!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgIDhkPqUCA%7CCgwI99PxnwYQ6PmUvQE%7C?hl=pl",
    user: {
      name: "Piotr",
      link: "https://www.google.com/maps/contrib/114531189143717044125?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegQIARBc",
      thumbnail: avatar8,
      reviews: 1,
    },
    rating: 5.0,
    date: "miesiąc temu",
    snippet:
      "Polecam pana Macieja. Pełen profesjonalizm. Poleca dobre, markowe produkty, które służą przez długie lata. Montaż paneli fotowoltaicznych jak najbardziej wykonany prawidłowo. Jestem bardzo zadowolony z usług. Polecam w 100%.",
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUN1dF9uckhnEAE!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICut_nrHg%7CCgwIh6nFlwYQ-NLP2gM%7C?hl=pl",
    user: {
      name: "Grzegorz",
      link: "https://www.google.com/maps/contrib/116829735787395871903?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegQIARBy",
      thumbnail: avatar1,
      local_guide: true,
      reviews: 8,
      photos: 109,
    },
    rating: 5.0,
    date: "7 miesięcy temu",
    snippet:
      "Fachowa obsługa, wszystko wykonane w terminie zgodnie z umową. Kontakt w trakcie instalacji FV jak i po zakończeniu inwestycji bez zarzutu. Polecam!",
    likes: 1,
    response: {
      date: "7 miesięcy temu",
      snippet: "Dziękujemy Panie Grzegorzu za zaufanie. Pozdrawiamy!",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNxeTZ5R2hRRRAB!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICqy6yGhQE%7CCgwIstSzhgYQgKeJyQE%7C?hl=pl",
    user: {
      name: "Mirosław",
      link: "https://www.google.com/maps/contrib/100129238093784109979?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegUIARCIAQ",
      thumbnail: avatar6,
      reviews: 5,
    },
    rating: 5.0,
    date: "rok temu",
    snippet:
      "Polecam zdecydowanie! Długo szukałem odpowiedniej firmy mnie skusiła merytoryka i wiedza przedstawiciela. Zastosowane rozwiązanie dobrego falownika Fronius 7.0 wraz z dobrymi panelami spowodowało że instalacja prawidłowo dobrana i dopasowana miast produkować 6700kWh rocznie osiągnęło poziom 7700 więc zdecydowanie wiekszy. Więc wiem że trafiłem doskonale. Polecam",
    likes: 1,
    response: {
      date: "rok temu",
      snippet:
        "Panie Mirku dziękuję za zaufanie, znamy się już kilka lat i na bieżąco wysyła mi Pan swoje osiągi w instalacji z czego jestem dumny i natchniony, że w swoich szeregach posiadam tak świadomego klienta.",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNxcmY3dHFnRRAB!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICqrf7tqgE%7CCgwIsPqhhgYQ0Mv2yQI%7C?hl=pl",
    user: {
      name: "Błażej",
      link: "https://www.google.com/maps/contrib/114012790434418828631?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegUIARCeAQ",
      thumbnail: avatar7,
      reviews: 2,
    },
    rating: 5.0,
    date: "rok temu",
    snippet:
      "Profesjonalne i indywidualne podejście do każdego klienta. Z czystym sumieniem moge polecić firmę odyGo Pana Macieja.",
    likes: 1,
    response: {
      date: "10 miesięcy temu",
      snippet:
        "Panie Błażeju otworzył mi Pan oczy na niektóre aspekty życiowe podczas naszych rozmów. Ot do czego prowadzi dyskusja na temat fotowotaliki i nie tylko. Pozdrawiam",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChdDSUhNMG9nS0VJQ0FnSUNxcmRiVG53RRAB!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICqrdbTnwE%7CCgwIkO2hhgYQuMeYvQM%7C?hl=pl",
    user: {
      name: "Michał",
      link: "https://www.google.com/maps/contrib/117230042090222343107?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegUIARC0AQ",
      thumbnail: avatar8,
      reviews: 1,
    },
    rating: 5.0,
    date: "rok temu",
    snippet:
      "Pełen profesjonalizm i doświadczenie, sprawiają, że zakładanie instalacji fotowoltaicznej to tylko z Panem Maciejem. Polecam.",
    likes: 1,
    response: {
      date: "10 miesięcy temu",
      snippet: "Panie Michale dziękuję jeszcze raz za zaufanie!",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNxcmE2NUZREAE!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICqra65FQ%7CCgwI0_OhhgYQsNqK1wM%7C?hl=pl",
    user: {
      name: "Łukasz",
      link: "https://www.google.com/maps/contrib/103745534887774616503?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegUIARDKAQ",
      thumbnail: avatar1,
      reviews: 3,
    },
    rating: 5.0,
    date: "rok temu",
    snippet: "Dwie instalacje zamontowane, dwie działają bez zarzutu. Polecam!",
    likes: 1,
    response: {
      date: "10 miesięcy temu",
      snippet:
        "Mam nadzieję, że kolejne instalację wykonane razem z Państwem będą równie owocne co już wykonane. Cieszę się na samą myśl o współpracy z Państwa firmą.",
    },
  },
  {
    link: "https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSUNxN3E2SU5REAE!2m1!1s0x0:0x99fe12f345bc5c0c!3m1!1s2@1:CIHM0ogKEICAgICq7q6INQ%7CCgwIx7T4hQYQ2JKZzAE%7C?hl=pl",
    user: {
      name: "Filip",
      link: "https://www.google.com/maps/contrib/116006404018720320423?hl=pl&sa=X&ved=2ahUKEwjsu_2DnYz-AhVqlIkEHUx0D44QvvQBegUIARDgAQ",
      thumbnail: avatar3,
      reviews: 2,
    },
    rating: 5.0,
    date: "rok temu",
    snippet:
      "Kontakt z klientem i pomoc w każdej kwestii, z którą można się zgłosić do Pana Maćka - TOP!",
    likes: 1,
    response: {
      date: "10 miesięcy temu",
      snippet: "Panie Filpie, zawsze do usług :)",
    },
  },
];
