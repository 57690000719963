import moneybox from "../../images/benefits-header/money-box.svg";
import earth from "../../images/benefits-header/earth.svg";
import thunder from "../../images/benefits-header/thunder.svg";
import pointer from "../../images/benefits-header/pointer.svg";
import incomes from "../../images/benefits-header/incomes.svg";
import ecologic from "../../images/benefits-header/ecologic.svg";

export const Data = [
  {
    text: (
      <p>
        Produkcja własnej energii elektrycznej oznacza{" "}
        <b>mniejsze rachunki za prąd</b>. Możesz nawet sprzedawać nadmiar
        energii do sieci i na tym zarabiać!
      </p>
    ),
    image: moneybox,
    altImage: "money-box",
  },
  {
    text: (
      <p>
        Fotowoltaika to zielona energia. Korzystanie z niej pozwala na{" "}
        <b>zmniejszenie emisji gazów cieplarnianych</b> i redukcję negatywnego
        wpływu na środowisko.
      </p>
    ),
    image: earth,
    altImage: "earth",
  },
  {
    text: (
      <p>
        Dzięki własnej instalacji przestaniesz być uzależnionym od zmian cen na
        rynku. Możesz produkować <b>własną energię</b> i korzystać z niej, kiedy
        chcesz.
      </p>
    ),
    image: thunder,
    altImage: "thunder",
  },
  {
    text: (
      <p>
        Instalacja fotowoltaiczna znacząco <b>zwiększy wartość</b> Twojej
        nieruchomości. Ekologia i oszczędność takiej instalacji może przyciągać
        potencjalnych nabywców.
      </p>
    ),
    image: pointer,
    altImage: "pointer",
  },
  {
    text: (
      <p>
        Jest to inwestycja, która <b>zwraca się w ciągu kilku lat</b>. Przekonaj
        się w jak krótkim czasie zapomnisz, że kiedyś trzeba było opłacać
        rachunki za prąd.
      </p>
    ),
    image: incomes,
    altImage: "incomes",
  },
  {
    text: (
      <p>
        Inwestując w fotowoltaikę przyczyniasz się do{" "}
        <b>rozwoju ekologicznych rozwiązań.</b>
      </p>
    ),
    image: ecologic,
    altImage: "ecologic",
  },
];
