import { ProjectGallery } from "../../page-components/ProjectsGallery/ProjectsGallery";
import { BaseLink, ProjectData } from "./ProjectsData";

import s from "./style.module.css";

export function Projects() {
  const backgroundStyle = {
    background: `url(https://test.odygo.pl/realizacje/01-bransk/webp-big/10-bialystok-podlaskie-podlasie-50kwp-panele-longi-solar-156szt-fotowoltaika-pompy-ciepla-klimatyzacje-magazyny-energii-odygo-maciej-odyjewski.webp)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "40% 31%",
  };
  return (
    <>
      <div className={s.baner} style={backgroundStyle}>
        <h1>Zobacz nasze dotychczasowe realizacje</h1>
      </div>
      <div className="header-container">
        <ProjectGallery
          title={"Fotowoltaika"}
          images={ProjectData}
          baseLink={BaseLink + "main/"}
        />
      </div>
    </>
  );
}
