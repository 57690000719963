import location from "../../images/footer/location-pin.svg";
import mail from "../../images/footer/mail.svg";
import phone from "../../images/footer/phone-icon.svg";
import openHours from "../../images/footer/open-hours.svg";

export const data = [
  {
    id: 0,
    name: "address",
    icon: location,
    content: "Adama Mickiewicza 59, 15-123 Białystok",
    onClick: () =>
      window.open(
        "https://maps.app.goo.gl/Se3h1YaZ2BLKuRBn9",
        "_blank"
      ),
  },
  {
    id: 1,
    name: "mail",
    icon: mail,
    content: "bialystok@odygo.pl",
    onClick: () => (window.location = `mailto:bialystok@odygo.pl`),
  },
  {
    id: 2,
    name: "phone",
    icon: phone,
    content: "517 529 445",
    onClick: () => (window.location = `tel:517 529 445`),
  },
  {
    id: 3,
    name: "open-hours",
    icon: openHours,
    content: "Pn - Pt: 8:00-17:00",
    onClick: () =>
      alert(
        "Godziny otwarcia:\nPoniedziałek - Piątek: 8:00 - 17:00,\nSobota: 8:00 - 15:00"
      ),
  },
];
