import "./global.css";
import "./style.css";
import "./styles/fonts.css";
import "./styles/colors.css";
import "./styles/containers.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { MainPage } from "./pages/MainPage/MainPage";
import { IndividualClient } from "./pages/IndividualClient/IndividualClient";
import { Company } from "./pages/Company/Company";
import { Farmer } from "./pages/Farmer/Farmer";
import { Contact } from "./pages/Contact/Contact";
import { ProjectDetails } from "./page-components/ProjectDetails/ProjectDetails";
import { Projects } from "./pages/Projects/Projects";
import { Photovoltaics } from "./pages/Photovoltaics/Photovoltaics";
import { HeatPumps } from "./pages/HeatPumps/HeatPumps";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { PhotovoltaicsForHome } from "./pages/Offer/Photovoltaics/PhotovoltaicsForHome";
import { PhotovoltaicsForFarmer } from "./pages/Offer/Photovoltaics/PhotovoltaicsForFarmer";
import { PhotovoltaicsForCompany } from "./pages/Offer/Photovoltaics/PhotovoltaicsForCompany";
import { PopUp } from "./page-components/PopUp/PopUp";
import { HomeAirConditioning } from "./pages/Offer/AirConditioning/HomeAirConditioning";
import { FarmerAirConditioning } from "./pages/Offer/AirConditioning/FarmerAirConditioning";
import { CompanyAirConditioning } from "./pages/Offer/AirConditioning/CompanyAirConditioning";
import { EnergyMagazines } from "./pages/Offer/EnergyMagazines/EnergyMagazines";
import { CarCharges } from "./pages/Offer/CarCharges/CarCharges";
import { CompanyCarCharges } from "./pages/Offer/CarCharges/CompanyCarCharges";
import { LandTenancy } from "./pages/Offer/LandTenancy/LandTenancy";
import { PhotovoltaicsFarms } from "./pages/Offer/PhotovoltaicsFarms/PhotovoltaicsFarms";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<MainPage />} />
          <Route path="/pop-up" element={<PopUp />} />
          <Route path="/klient-indywidualny" element={<IndividualClient />} />
          <Route path="/oferta-dla-firm" element={<Company />} />
          <Route path="/oferta-dla-rolnikow" element={<Farmer />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/realizacja/:projectLink" element={<ProjectDetails />} />
          <Route path="/realizacje" element={<Projects />} />
          <Route path="/fotowoltaika" element={<Photovoltaics />} />
          <Route
            path="/oferta/fotowoltaika-dla-domu"
            element={<PhotovoltaicsForHome />}
          />

          <Route
            path="/oferta/fotowoltaika-dla-rolnika"
            element={<PhotovoltaicsForFarmer />}
          />
          <Route
            path="/oferta-dla-firm/fotowoltaika"
            element={<PhotovoltaicsForCompany />}
          />
          <Route
            path="/oferta/klimatyzacje-dla-domu"
            element={<HomeAirConditioning />}
          />
          <Route
            path="/oferta/klimatyzacje-dla-rolnika"
            element={<FarmerAirConditioning />}
          />
          <Route
            path="/oferta-dla-firm/klimatyzacje"
            element={<CompanyAirConditioning />}
          />
          <Route path="/pompy-ciepla" element={<HeatPumps />} />
          <Route
            path="/oferta/ladowarki-samochodowe"
            element={<CarCharges />}
          />
          <Route
            path="/oferta-dla-firm/ladowarki-samochodowe"
            element={<CompanyCarCharges />}
          />
          <Route
            path="/oferta-dla-rolnikow/dzierzawa-ziemii"
            element={<LandTenancy />}
          />
          <Route
            path="/oferta/magazyny-energii"
            element={<EnergyMagazines />}
          />
          <Route
            path="/oferta/farmy-fotowoltaiczne"
            element={<PhotovoltaicsFarms />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);
