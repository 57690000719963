import header from "../../images/farmer-page/rolnik-wies-fotowoltaika-traktor-rolnictwo-odygo-bialystok.webp";
import dontation1 from "../../images/farmer-page/rolnik-fotowoltaika-farma-rolnictwo-dofinansowanie-odygo-bialystok.webp";
import offer1 from "../../images/farmer-page/rolnik-wies-fotowoltaika-traktor-rolnictwo-odygo-bialystok-big.webp";
import offer2 from "../../images/farmer-page/rolnik-klimatyzacja-farma-rolnictwo-odygo-bialystok-big.webp";
import offer3 from "../../images/individual-page/dom-magazyn-energii-zielone-energia-fotowoltaika-odygo-bialystok-big.webp";
import offer4 from "../../images/individual-page/dom-ladowarka-samochodowa-ladowanie-samochod-elektryczny-zielona-energia-odygo-bialystok-big.webp";
import offer5 from "../../images/farmer-page/rolnik-fotowoltaika-pole-dzierzawa-ziemi-odygo-bialystok-big.webp";

export const FARMER_HEADER = {
  title: (
    <>
      Poznaj naszą ofertę dla <span className="special">rolników</span>
    </>
  ),
  description: (
    <>
      Chcesz zacząć korzystać z odnawialnych źródeł energii na swoim
      gospodarstwie? Skorzystaj z naszej oferty i zainstaluj panele
      fotowoltaiczne, pompy ciepła lub wiatraki. Dzięki temu{" "}
      <b>zyskasz niezależność energetyczną</b> i oszczędzisz na rachunkach.
      Skontaktuj się z nami, a pomożemy Ci dobrać najlepsze rozwiązanie dla
      Twojego gospodarstwa.
    </>
  ),
  background: header,
  backgroundPosition: "50% 30%",
};

export const FARMER_DONTATIONS = {
  title: "Sprawdź dotacje dla rolników",
  program: "Energia dla wsi",
  description: (
    <>
      <b>Pożyczka do 25 mln zł.</b> Dotacja do 20 mln zł. Dotacja do 45% kosztów
      (65% dla mikro i małych przedsiębiorstw, 55% dla średnich). Pożyczka do
      100% kosztów kwalifikowanych.
    </>
  ),
  background: dontation1,
};

export const FARMER_OFFERS = [
  {
    title: "Fotowoltaika dla wsi",
    description:
      "Jesteś rolnikiem i chcesz zredukować koszty energii na farmie? Fotowoltaika dla wsi to doskonała inwestycja. Nasze systemy są odporne na różne warunki pogodowe i łatwe w utrzymaniu. Oferujemy kompleksową obsługę, od projektu po instalację. Dowiedz się więcej i zobacz, jak możesz zwiększyć rentowność swojej działalności. Zainwestuj w zrównoważony rozwój i oszczędzaj na kosztach operacyjnych.",
    background: offer1,
    link: "/oferta/fotowoltaika-dla-rolnika",
  },
  {
    title: "Klimatyzacja dla wsi",
    description:
      "Czy upalne dni utrudniają pracę na farmie? A może chcesz zapewnić odpowiednie warunki dla swoich zwierząt? Klimatyzacja dla wsi to rozwiązanie, które z pewnością Cię zainteresuje. Nasze systemy są wydajne i mogą być dostosowane do różnych potrzeb. Oferujemy zarówno małe, jak i duże jednostki, idealne dla różnych typów budynków. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz zwiększyć komfort i efektywność na swojej farmie.",
    background: offer2,
    backgroundPosition: "50% 40%",
    link: "/oferta/klimatyzacje-dla-rolnika",
  },
  {
    title: "Magazyny energii",
    description:
      "Chcesz magazynować energię słoneczną na później? Odkryj, jak magazyny energii mogą zwiększyć efektywność Twojego domowego systemu fotowoltaicznego. Nasze rozwiązania są nie tylko praktyczne, ale też ekonomiczne. Magazynowanie energii to przyszłość, która zaczyna się już dziś. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz stać się bardziej niezależnym energetycznie.",
    background: offer3,
    backgroundPosition: "50% 20%",
    link: "/oferta/magazyny-energii",
  },
  {
    title: "Ładowarki samochodowe",
    description:
      "Jeździsz samochodem elektrycznym? Zainwestuj w domową ładowarkę i ciesz się niezależnością. Nasze ładowarki są szybkie i bezpieczne, co sprawia, że są idealnym rozwiązaniem dla każdego właściciela samochodu elektrycznego. Oferujemy różne modele, dostosowane do różnych typów samochodów. Kliknij, aby poznać naszą ofertę i dowiedzieć się, jak możesz zyskać na komforcie i oszczędnościach.",
    background: offer4,
    backgroundPosition: "50% 0%",
    link: "/oferta/ladowarki-samochodowe",
  },
  {
    title: "Dzierżawa ziemi",
    description:
      "Masz nieużytkowaną ziemię i chcesz na niej zarobić? Dowiedz się, jak możesz ją dzierżawić pod farmy fotowoltaiczne. To świetny sposób na dodatkowy dochód i promowanie zrównoważonego rolnictwa. Oferujemy atrakcyjne warunki i pełne wsparcie na każdym etapie. Kliknij, aby poznać szczegóły i dowiedzieć się, jak możesz zacząć zarabiać na swojej ziemi.",
    background: offer5,
    link: "/oferta-dla-rolnikow/dzierzawa-ziemii",
  },
];
