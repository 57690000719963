import homeBaner from "../../../images/individual-page/dom-fotowoltaika-panele-dach-odygo-bialystok-big.webp";
import farmerBaner from "../../../images/farmer-page/rolnik-wies-fotowoltaika-traktor-rolnictwo-odygo-bialystok-big.webp";
import companyBaner from "../../../images/company-page/firma-fotowoltaika-klimatyzacja-pompa-ciepla-odygo-bialystok-big.webp";

export const PhotovoltaicsForHomeData = {
  title: "Fotowoltaika dla domu",
  baner: homeBaner,
  content: (
    <>
      <h3>Dlaczego fotowoltaika?</h3>
      <p>
        Fotowoltaika to inwestycja w przyszłość, która przynosi korzyści zarówno
        dla Ciebie, jak i dla środowiska. Dzięki panelom słonecznym możesz
        znacząco obniżyć rachunki za prąd i stać się bardziej niezależnym
        energetycznie. Ponadto, korzystając z odnawialnych źródeł energii,
        przyczyniasz się do redukcji emisji CO2 i walki z globalnym ociepleniem.
        Jeśli mieszkasz w Białymstoku lub okolicach Podlasia, OdyGO jest Twoim
        lokalnym partnerem w dziedzinie odnawialnych źródeł energii. Nasza
        firma, będąca liderem na rynku odnawialnych źródeł energii w regionie,
        oferuje kompleksowe rozwiązania dostosowane do indywidualnych potrzeb
        każdego klienta.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Panele słoneczne zamieniają energię słoneczną na prąd elektryczny, który
        można wykorzystać w domu. System fotowoltaiczny składa się z paneli,
        inwertera i ewentualnie magazynu energii. Wszystko to jest połączone w
        spójny system, który jest łatwy w obsłudze i utrzymaniu. Działa to na
        zasadzie przemiany energii słonecznej w energię elektryczną, która jest
        następnie wykorzystywana w gospodarstwie domowym. OdyGO, z siedzibą w
        Białymstoku, oferuje profesjonalne doradztwo i instalację systemów
        fotowoltaicznych na terenie całego Podlasia.
      </p>
      <h3>Nasza oferta dla domu</h3>
      <p>
        Oferujemy kompleksową obsługę, począwszy od doradztwa, przez projekt, aż
        po instalację i serwis. Nasze panele słoneczne są wysokiej jakości i
        objęte są długoterminową gwarancją. Dodatkowo, oferujemy różne opcje
        finansowania, aby każdy mógł sobie pozwolić na tę ekologiczną
        inwestycję. Jeśli jesteś mieszkańcem Białegostoku lub innych części
        Podlasia, skontaktuj się z nami, aby dowiedzieć się więcej o tym, jak
        możesz zacząć korzystać z odnawialnych źródeł energii i stać się
        bardziej niezależnym energetycznie.
      </p>
    </>
  ),
};

export const PhotovoltaicsForFarmerData = {
  title: "Fotowoltaika dla domu",
  baner: farmerBaner,
  banerPosition: "50% 25%",
  content: (
    <>
      <h3>Dlaczego Fotowoltaika dla wsi?</h3>
      <p>
        Fotowoltaika to idealne rozwiązanie dla rolników i właścicieli dużych
        posiadłości na wsi. Dzięki panelom fotowoltaicznym można znacząco
        obniżyć koszty energii, co jest szczególnie ważne w rolnictwie, gdzie
        rachunki za prąd mogą być bardzo wysokie. Jeżeli mieszkasz na wsi w
        okolicach Białegostoku lub na Podlasiu, OdyGO oferuje specjalne
        rozwiązania fotowoltaiczne dostosowane do potrzeb rolnictwa.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Podobnie jak w przypadku fotowoltaiki dla domu, panele słoneczne
        zamieniają energię słoneczną na prąd elektryczny, który można
        wykorzystać w gospodarstwie. OdyGO, z siedzibą w Białymstoku, oferuje
        profesjonalne doradztwo i instalację systemów fotowoltaicznych na
        terenie całego Podlasia, z uwzględnieniem specyfiki rolnictwa i potrzeb
        rolników.
      </p>
      <h3>Nasza oferta dla wsi</h3>
      <p>
        Jeśli jesteś rolnikiem i zainteresowany jesteś fotowoltaiką, OdyGO ma
        dla Ciebie atrakcyjną ofertę. Oferujemy różne modele paneli słonecznych,
        które są idealnie dostosowane do potrzeb rolnictwa. Nasze produkty są
        wysokiej jakości i objęte są długoterminową gwarancją. Dodatkowo,
        oferujemy różne opcje finansowania, aby każdy rolnik mógł sobie pozwolić
        na tę ekologiczną i ekonomiczną inwestycję.
      </p>
    </>
  ),
};

export const PhotovoltaicsForCompanyData = {
  title: "Fotowoltaika dla Twojej firmy",
  baner: companyBaner,
  content: (
    <>
      <h3>Dlaczego Fotowoltaika dla firm?</h3>
      <p>
        Fotowoltaika dla firm to nie tylko ekologiczny wybór, ale również
        ekonomiczny. Dzięki panelom fotowoltaicznym, Twoja firma może znacząco
        obniżyć koszty energii elektrycznej i zwiększyć swoją konkurencyjność na
        rynku. Jeżeli prowadzisz firmę w Białymstoku lub na Podlasiu, OdyGO
        oferuje profesjonalne rozwiązania fotowoltaiczne dostosowane do potrzeb
        przedsiębiorców.
      </p>
      <h3>Jak to działa?</h3>
      <p>
        Panele fotowoltaiczne zamieniają energię słoneczną na prąd elektryczny,
        który można wykorzystać w firmie. OdyGO, z siedzibą w Białymstoku,
        oferuje profesjonalne doradztwo i instalację systemów fotowoltaicznych
        na terenie całego Podlasia, z uwzględnieniem specyfiki różnych branż i
        potrzeb przedsiębiorców.
      </p>
      <h3>Nasza oferta dla firm</h3>
      <p>
        Jeśli prowadzisz firmę i jesteś zainteresowany fotowoltaiką, OdyGO ma
        dla Ciebie atrakcyjną ofertę. Oferujemy różne modele paneli słonecznych,
        które są idealnie dostosowane do potrzeb przedsiębiorstw. Nasze produkty
        są wysokiej jakości i objęte są długoterminową gwarancją. Dodatkowo,
        oferujemy różne opcje finansowania, aby każdy przedsiębiorca mógł sobie
        pozwolić na tę ekologiczną i ekonomiczną inwestycję.
      </p>
    </>
  ),
};
