import { Outlet } from "react-router";
import { Footer } from "./components/Footer/Footer";
import { Menu } from "./components/Menu/Menu";
import { PopUp } from "./page-components/PopUp/PopUp";

function App() {
  return (
    <>
      <PopUp />
      <Menu />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
