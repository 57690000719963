export function Baner({ baner, banerPosition }) {
  return (
    <>
      {baner && (
        <div
          className="baner"
          style={{
            background: `url(${baner})`,
            backgroundPosition: `${banerPosition ?? "50% 50%"}`,
          }}
        ></div>
      )}
    </>
  );
}
