import s from "./style.module.css";
import stars from "../../images/company-opinions/stars.svg";
import { useState } from "react";

export function Opinion({ name, avatar, review }) {
  const [readMore, setReadMore] = useState(false);
  const showMoreText = "zobacz więcej";
  const showLessText = "zobacz mniej";
  const [tipText, showTipText] = useState(showMoreText);
  const maxTextLenght = 191;
  const fullReview = review;
  const isLongReview = fullReview.length > maxTextLenght;
  let shortText = `${review.substring(0, maxTextLenght)}(...) `;
  for (let i = maxTextLenght; i < fullReview.length; i++) {
    if (review[i] === " ") {
      shortText = `${review.substring(0, i)}(...) `;
      break;
    }
  }

  const onClickItem = () => {
    setReadMore(!readMore);
    showTipText(tipText === showMoreText ? showLessText : showMoreText);
  };

  return (
    <>
      <div className={s.main_padding}>
        <div className={s.container}>
          <div className={s.user}>
            <img src={avatar} className={s.avatar} alt="avatar" />
            <div className="bodycopy-xl">{name}</div>
          </div>

          <img src={stars} alt="stars" />
          <div className={s.text}>
            {readMore || !isLongReview ? fullReview : shortText}
            {isLongReview ? (
              <b className={s.pointer} onClick={onClickItem}>
                {" "}
                {readMore ? <br /> : ""}
                {tipText}
              </b>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
