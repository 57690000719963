import s from "./style.module.css";
import { Data } from "./Data";
import { Card } from "../../components/Card/Card";
import SuperSlider from "../../components/OpinionList/SuperSlider";

export function BenefitsHeader() {
  const items = Data.map((item) => (
    <Card
      key={Data.indexOf(item)}
      text={item.text}
      image={item.image}
      altImage={item.altImage}
    />
  ));

  return (
    <>
      <div className="container center-xl">
        <h2>Sprawdź co możesz zyskać</h2>
        <div className={s.content}>{items}</div>
        <div className="mobile-container">
          <SuperSlider
            items={items}
            enableCenterMode={false}
            rows={2}
            variableWidth={false}
          />
        </div>
      </div>
    </>
  );
}
