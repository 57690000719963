import { BenefitsHeader } from "../../page-components/BenefitsHeader/BenefitsHeader";
import { CompanyNumbers } from "../../page-components/CompanyNumbers/CompanyNumbers";
import { CompanyOpinions } from "../../page-components/CompanyOpinions/CompanyOpinions";
import { PerfectOffer } from "../../page-components/PerfectOffer/PerfectOffer";
import { PreviousProjects } from "../../page-components/PreviousProjects/PreviousProjects";
import { PhotovoltaicsDefinition } from "../../page-components/PhotovoltaicsDefinition/PhotovoltaicsDefinition";
import { WhyUs } from "../../page-components/WhyUs/WhyUs";

export const MAIN_PAGE_HEADERS = [
  {
    id: "photovoltaics",
    header: <PhotovoltaicsDefinition />,
    hasBackground: false,
    hasOwnContainer: false,
  },
  {
    id: "photovoltaics-benefits",
    header: <BenefitsHeader />,
    hasBackground: true,
    hasOwnContainer: false,
  },
  {
    id: "why-us",
    header: <WhyUs />,
    hasBackground: false,
    hasOwnContainer: false,
  },
  {
    id: "perfect-photovoltaics",
    header: <PerfectOffer title="Oferta skrojona idealnie pod Ciebie" />,
    hasBackground: true,
    hasOwnContainer: false,
  },
  {
    id: "previous-photovoltaics-projects",
    header: <PreviousProjects />,
    hasBackground: false,
    hasOwnContainer: true,
  },
  {
    id: "company-numbers",
    header: <CompanyNumbers />,
    hasBackground: false,
    hasOwnContainer: true,
  },
  {
    id: "company-opinions",
    header: <CompanyOpinions />,
    hasBackground: false,
    hasOwnContainer: true,
  },
];
