import header from "../../images/company-page/firma-fotowoltaika-uscisk-dloni-umowa-profesjonalizm-odygo-bialystok.webp";
import donation1 from "../../images/company-page/firma-fotowoltaika-infrastruktura-dofinansowanie-odygo-bialystok.webp";
import offer1 from "../../images/company-page/firma-fotowoltaika-klimatyzacja-pompa-ciepla-odygo-bialystok-big.webp";
import offer3 from "../../images/company-page/firma-fotowoltaika-klimatyzacja-pompa-ciepla-odygo-bialystok-big.webp";
import offer4 from "../../images/individual-page/dom-magazyn-energii-zielone-energia-fotowoltaika-odygo-bialystok-big.webp";
import offer5 from "../../images/individual-page/dom-ladowarka-samochodowa-ladowanie-samochod-elektryczny-zielona-energia-odygo-bialystok-big.webp";

export const COMPANY_HEADER = {
  title: (
    <>
      Poznaj naszą ofertę dla <span className="special">firm</span>
    </>
  ),
  description: (
    <>
      Zainwestuj w przyszłość Twojej firmy! Skorzystaj z naszej oferty i
      zainstaluj panele fotowoltaiczne, klimatyzację oraz farmy fotowoltaiczne.{" "}
      <b>Oszczędzaj na kosztach energii</b> i dbaj o środowisko jednocześnie.
      Skontaktuj się z nami już dziś!
    </>
  ),
  background: header,
};

export const COMPANY_DONATIONS = {
  title: "Sprawdź dotacje dla firm",
  program: "Energia Plus",
  description: (
    <>
      Dotacja do <b>50% kosztów</b> kwalifikowanych (jedynie dla przedsięwzięć
      wykorzystujących technologię OCR). Pożyczka do <b>85% kosztów</b>
      kwalifikowanych: <b>od 0,5mln do 500 mln zł</b>
    </>
  ),
  background: donation1,
};

export const COMPANY_OFFERS = [
  {
    title: "Fotowoltaika dla firm",
    description:
      "Chcesz zredukować koszty operacyjne Twojej firmy i zyskać ekologiczny wizerunek? Zainwestuj w fotowoltaikę dla firm. Nasze rozwiązania są skalowalne i mogą być dostosowane do różnych potrzeb. Oferujemy kompleksową obsługę, od projektu po instalację. Kliknij, aby poznać szczegóły i dowiedzieć się, jak możesz zmodernizować swoją firmę i stać się liderem w dziedzinie zrównoważonego rozwoju.",
    background: offer1,
    link: "/oferta-dla-firm/fotowoltaika",
  },
  {
    title: "Farmy fotowoltaiczne",
    description:
      "Interesuje Cię inwestycja w odnawialne źródła energii na większą skalę? Dowiedz się, jak możesz zbudować własną farmę fotowoltaiczną. Nasze systemy są wydajne i odporne na różne warunki pogodowe. Oferujemy różne opcje finansowania i pełne wsparcie techniczne. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz stać się częścią rewolucji energetycznej.",
    background: donation1,
    link: "/oferta/farmy-fotowoltaiczne",
  },
  {
    title: "Klimatyzacja dla firm",
    description:
      "Potrzebujesz efektywnego systemu klimatyzacji dla Twojego biura lub hali produkcyjnej? Odkryj nasze rozwiązania dla firm. Nasze systemy są energooszczędne i mogą być dostosowane do różnych potrzeb. Oferujemy zarówno jednostki ścienne, jak i centralne systemy klimatyzacyjne. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz zwiększyć komfort i efektywność w miejscu pracy.",
    background: offer3,
    link: "/oferta-dla-firm/klimatyzacje",
  },
  {
    title: "Magazyny energii",
    description:
      "Chcesz magazynować energię słoneczną na później? Odkryj, jak magazyny energii mogą zwiększyć efektywność Twojego domowego systemu fotowoltaicznego. Nasze rozwiązania są nie tylko praktyczne, ale też ekonomiczne. Magazynowanie energii to przyszłość, która zaczyna się już dziś. Kliknij, aby dowiedzieć się więcej i zobacz, jak możesz stać się bardziej niezależnym energetycznie.",
    background: offer4,
    link: "/oferta/magazyny-energii",
  },
  {
    title: "Ładowarki samochodowe dla firm",
    description:
      "Chcesz zaoferować pracownikom i klientom możliwość ładowania samochodów elektrycznych? Zainwestuj w ładowarki dla firm. Nasze rozwiązania są szybkie i bezpieczne, co sprawia, że są idealnym dodatkiem do każdej firmy. Oferujemy różne modele, dostosowane do różnych typów samochodów. Dowiedz się więcej i zobacz, jak możesz zwiększyć atrakcyjność swojego miejsca pracy i stać się bardziej konkurencyjnym na rynku.",
    background: offer5,
    link: "/oferta-dla-firm/ladowarki-samochodowe",
  },
];
