import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import circle from "../../images/company-opinions/empty-circle.svg";
import whiteCircle from "../../images/company-opinions/white-circle.svg";
import leftArrow from "../../images/company-opinions/left-arrow.svg";
import rightArrow from "../../images/company-opinions/right-arrow.svg";

export default class SuperSlider extends React.Component {
  render() {
    const Arrow = (props) => {
      let className = props.type === "next" ? "nextArrow" : "prevArrow";
      className += " arrow";
      const char =
        props.type === "next" ? (
          <img src={rightArrow} alt="right arrow" />
        ) : (
          <img src={leftArrow} alt="left arrow" />
        );
      return (
        <span className={className} onClick={props.onClick}>
          {char}
        </span>
      );
    };

    const settings = {
      dots: true,
      slidesToScroll: 1,
      centerMode: this.props.enableCenterMode,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
      customPaging: (i) => (
        <img
          className="circle"
          src={this.props.whiteCircle ? whiteCircle : circle}
          alt="empty circle"
        />
      ),
      appendDots: (dots) => (
        <div>
          <ul
            className={`navigation ${
              this.props.absoluteMenu ? "navigation-absolute" : ""
            }`}
          >
            {" "}
            {dots}{" "}
          </ul>
        </div>
      ),
      variableWidth: this.props.variableWidth ?? true,
    };

    return (
      <Slider rows={this.props.rows ?? 1} {...settings}>
        {this.props.items}
      </Slider>
    );
  }
}
