export const DROPDOWN_DATA = {
  name: "Oferta",
  arrow: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2430_1962"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          x="24"
          width="24"
          height="24"
          transform="rotate(90 24 0)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2430_1962)">
        <path
          d="M6.69319 9.40008L7.40089 8.69238L12.0009 13.2924L16.6009 8.69238L17.3086 9.40008L12.0009 14.7078L6.69319 9.40008Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  options: [
    {
      name: "Klient indywidualny",
      link: "/klient-indywidualny",
    },
    {
      name: "Rolnik",
      link: "/oferta-dla-rolnikow",
    },
    {
      name: "Firma",
      link: "/oferta-dla-firm",
    },
  ],
};
