import ratingStars from "../../images/company-numbers/rating-stars.svg";
import satisfaction from "../../images/company-numbers/satisfaction.svg";
import solarHouse from "../../images/company-numbers/solar-house.svg";
import thunderbolt from "../../images/company-numbers/thunderbolt.svg";
import airConditioner from "../../images/company-numbers/air-conditioner.svg";

export const data = [
  {
    id: 0,
    number: "7",
    icon: ratingStars,
    text: "Lat na rynku",
  },
  {
    id: 1,
    number: "100",
    icon: satisfaction,
    text: "Zadowolonych klientów",
  },
  {
    id: 2,
    number: "654",
    icon: solarHouse,
    text: "Zrealizowanych instalacji",
  },
  {
    id: 3,
    number: "3720",
    icon: thunderbolt,
    text: "Łączna moc instalacji w kWp",
  },
  {
    id: 4,
    number: "147",
    icon: airConditioner,
    text: "Wykonanych pomp ciepła",
  },
];
