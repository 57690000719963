import s from "./style.module.css";
import { Form } from "../Form/Form";

export function ContactUs() {
  return (
    <>
      <div className={`${s.flex} ${s.flex_column}`}>
        <h2>Skontaktuj się z nami</h2>
        <div className={`${s.flex} ${s.gap_12}`}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.890517241572!2d23.174879588281488!3d53.117940915350076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ffdd7bf8822d9%3A0x99fe12f345bc5c0c!2sodyGO!5e0!3m2!1sen!2spl!4v1714414424236!5m2!1sen!2spl"
            className={s.map}
            allowFullScreen=""
            loading="eager"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <Form />
        </div>
      </div>
    </>
  );
}
