import s from "./style.module.css";

export function PrimaryButton({ text, onClick, isSubmit }) {
  return (
    <>
      <button
        className={s.btn}
        type={isSubmit ? "submit" : "button"}
        onClick={onClick}
      >
        <div className={s.btn_text}>{text}</div>
      </button>
    </>
  );
}
