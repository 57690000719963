import { Thumbnail } from "../../components/Thumbnail/Thumbnail";
import s from "./style.module.css";
import { data, lastProject } from "./Data";
import SuperSlider from "../../components/OpinionList/SuperSlider";

export function PreviousProjects() {
  const isMainPage = window.location.pathname === "/";
  const projects = data.map((item, index) => (
    <div key={index} className={s.main_padding}>
      <Thumbnail
        title={item.name}
        subtitle={item.place}
        image={item.image}
        onClickItem={() => window.open(`/realizacja/${item.link}`, "_self")}
      />
    </div>
  ));
  const moreItemsThumbnail = (
    <div key={projects.length} className={s.main_padding}>
      <Thumbnail
        title={lastProject.name}
        onlyTitle={true}
        image={lastProject.image}
        onClickItem={() => window.open("/realizacje", "_self")}
      />
    </div>
  );
  projects.push(moreItemsThumbnail);
  const title = (
    <>
      Zobacz nasze <span className="disable-mobile">dotychczasowe</span>{" "}
      realizacje
    </>
  );

  return (
    <>
      <div className={`${isMainPage ? "header-container side-padding" : ""}`}>
        <div className="container m-gap">
          <h2>{title}</h2>
          <div className="grid grid-xl">{projects}</div>
        </div>
        <div className="mobile-container">
          <SuperSlider items={projects} enableCenterMode={false} />
        </div>
      </div>
    </>
  );
}
