import s from "./style.module.css";
import hero from "../../images/hero-page/hero.webp";
import { BottomButton } from "../../components/BottomButton/BottomButton";

export function HeroPage() {
  return (
    <>
      <div className={s.bg}>
        <div className={s.main_container}>
          <div className={s.container}>
            <div className={s.header_container}>
              <img src={hero} className={s.image} alt="dark blue background" />

              <div className={s.title}>
                Energia ze słońca zamieniana w{" "}
                <span className="special">oszczędność</span>
              </div>
              <div className={s.subtitle}>
                Chcesz zacząć oszczędzać na rachunkach za prąd i jednocześnie
                dbać o środowisko? Sprawdź naszą ofertę już teraz i ciesz się{" "}
                <span className="bold">darmową energią </span>
                ze słońca!{" "}
              </div>
              <BottomButton text="Wyślij zapytanie" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
