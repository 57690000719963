import { PerfectOffer } from "../../page-components/PerfectOffer/PerfectOffer";

export function Photovoltaics() {
  const title = "Zrób krok ku zielonej energetyce fotowoltaika z OdyGO";
  const additionalContent = (
    <>
      <div className="content-container">
        <h3>Dlaczego fotowoltaika?</h3>
        <p className="max-text">
          Fotowoltaika to nie tylko sposób na obniżenie rachunków za energię,
          ale również krok w stronę bardziej zrównoważonego i ekologicznego
          stylu życia. Wykorzystując energię słoneczną, możesz stać się bardziej
          niezależnym od tradycyjnych źródeł energii i przyczynić się do ochrony
          środowiska.
        </p>
        <h3>Nasza oferta</h3>
        <p className="max-text">
          Oferujemy kompleksową obsługę, począwszy od doradztwa i analizy
          potrzeb, przez projektowanie i montaż instalacji, aż po serwis i
          utrzymanie. Nasze usługi są dostosowane do indywidualnych potrzeb
          każdego klienta, co sprawia, że jesteśmy niekwestionowanym liderem na
          rynku odnawialnych źródeł energii w Białymstoku i województwie
          podlaskim.
        </p>
        <h3>Dlaczego wybrać OdyGO?</h3>
        <ul className="max-text list">
          <li>
            Doświadczenie: Działamy na rynku od 2017 roku i mamy setki
            zadowolonych klientów
          </li>
          <li>
            Lokalny Ekspert: Jesteśmy firmą z Białegostoku, ale obsługujemy
            klientów na terenie całej Polski
          </li>
          <li>
            Wysoka Jakość Usług: Nasza firma ma szeroko rozwinięty układ
            moralny, który wpływa na jakość naszych usług i podejście do klienta
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <div className="header-container">
        <PerfectOffer
          title={title}
          isHomePage={false}
          offerKeyword="Fotowoltaika"
        />
      </div>
      <div className="header-bg">
        <div className="header-container">{additionalContent}</div>
      </div>
    </>
  );
}
