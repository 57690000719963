import { Article } from "../../../components/Article/Article";
import { PhotovoltaicsForCompanyData } from "./PhotovoltaicsData";

export function PhotovoltaicsForCompany() {
  return (
    <>
      <Article {...PhotovoltaicsForCompanyData} />
    </>
  );
}
