import { Opinion } from "../../components/Opinion/Opinion";
import { useState } from "react";
import { useEffect } from "react";
import { data } from "./data";
import s from "./style.module.css";
import SuperSlider from "../../components/OpinionList/SuperSlider";

export function CompanyOpinions() {
  const [allOpinions, setAllOpinions] = useState([]);

  useEffect(() => {
    let opinions = [];

    for (let i = 0; i < data.length; i++) {
      let review = data[i];
      let thumbnail = review.user.thumbnail;
      let name = review.user.name;

      opinions.push(
        <Opinion
          key={i}
          avatar={thumbnail}
          review={review.snippet}
          name={name}
        />
      );
    }
    setAllOpinions(opinions);
  }, []);

  return (
    <>
      <div className="header-container">
        <h2 className={s.title}>
          To mówią o nas <span className="disable-mobile">klienci</span>
        </h2>

        <SuperSlider items={allOpinions} enableCenterMode={true} />
      </div>
    </>
  );
}
