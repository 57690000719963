import check from "../../images/checkbox/check.svg";
import s from "./style.module.css";

export function Checkbox({ text, isChecked, onClickCheck }) {
  return (
    <>
      <div className={s.control} onClick={onClickCheck}>
        <div className={s.box}>
          {isChecked && <img src={check} alt="check" />}
          <input
            className={s.input}
            type="checkbox"
            checked={isChecked}
            readOnly
          />
        </div>
        <div className={s.small_text}>{text}</div>
      </div>
    </>
  );
}
