import { DotationTemplate } from "../../page-components/DotationTemplate/DotationTemplate";
import { TemplateHeader } from "../../page-components/TemplateHeader/TemplateHeader";
import { TemplateOffer } from "../../page-components/TemplateOffer/TemplateOffer";
import {
  COMPANY_HEADER,
  COMPANY_DONATIONS,
  COMPANY_OFFERS,
} from "./CompanyData";

export function Company() {
  const header = (
    <TemplateHeader
      title={COMPANY_HEADER.title}
      subtitle={COMPANY_HEADER.description}
      backgroundUrl={COMPANY_HEADER.background}
    />
  );

  const dontation = (
    <DotationTemplate
      title={COMPANY_DONATIONS.title}
      program={COMPANY_DONATIONS.program}
      description={COMPANY_DONATIONS.description}
      background={COMPANY_DONATIONS.background}
    />
  );

  const offers = COMPANY_OFFERS.map((x) => (
    <TemplateOffer
      background={x.background}
      title={x.title}
      description={x.description}
      link={x.link}
    />
  ));

  return (
    <>
      <header>{header}</header>
      <main>
        {dontation}
        {offers}
      </main>
    </>
  );
}
