import { Article } from "../../../components/Article/Article";
import { DataPhotovoltaicsFarms } from "./DataPhotovoltaicsFarms";

export function PhotovoltaicsFarms() {
  return (
    <>
      <Article {...DataPhotovoltaicsFarms} />
    </>
  );
}
