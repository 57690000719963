import s from "./style.module.css";
import { data } from "./Data";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";

export function PerfectOffer({ title, isHomePage = true, offerKeyword }) {
  const onClickItem = (item) => {
    if (isHomePage) {
      window.open(item.link, "_self");
      return;
    }

    let link;
    switch (item.id) {
      case "IND":
        link = "/oferta/fotowoltaika-dla-domu";
        break;
      case "FAR":
        link = "/oferta/fotowoltaika-dla-rolnika";
        break;
      case "CMP":
        link = "/oferta/fotowoltaika-dla-firmy";
        break;
      default:
        break;
    }

    window.open(link, "_self");
  };

  return (
    <>
      <div className="container m-gap">
        <h2>{title}</h2>
        <div className={s.text}>
          <p>
            W OdyGo wiemy, że każdy nasz klient jest wyjątkowy i ma swoje
            indywidualne potrzeby, dlatego stawiamy na ofertę, która jest
            idealnie dopasowana do Twoich wymagań. Niezależnie od tego, czy
            jesteś <b>rolnikiem</b>, <b>osobą prywatną</b> czy <b>firmą</b>,
            proponujemy najlepsze rozwiązania, które pozwolą Ci na wykorzystanie
            potencjału energii odnawialnej.
          </p>
        </div>
        <div className={s.images_container}>
          {data.map((item) => (
            <div
              key={data.indexOf(item)}
              className={s.image_container}
              onClick={() => onClickItem(item)}
            >
              <img className={s.image} src={item.image} alt={s.headline} />
              <div className={s.headline}>
                <div className="bodycopy-xl">{item.headline}</div>
              </div>
              <div className={s.detail}>
                <h4>{item.special.title}</h4>
                <p>{item.special.content}</p>
                <PrimaryButton
                  text={
                    isHomePage
                      ? `Sprawdź ofertę`
                      : `${offerKeyword} ${item.offer}`
                  }
                  onClick={() => onClickItem(item)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
