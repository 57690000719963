import s from "./style.module.css";
import { useEffect, useState } from "react";
import { Icons } from "../Icons/Icons";
import { PHONE } from "../Icons/IconsData";
import { LOGO, MENU_ITEMS } from "./data.js";
import menu from "../../images/menubar/menu.svg";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useScrollDirection } from "../../utils/useScrollDirection";
import { MenuItemDropdown } from "../MenuItemDropdown/MenuItemDropdown";

export function Menu() {
  const [currentScrollPos, setCurrentScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const pathname = window.location.pathname;
  const isMainPage = pathname === "/";
  const { width } = useWindowDimensions();
  const scrollDirection = useScrollDirection();
  const origin = window.location.origin;

  var scrollMaxY =
    window.scrollMaxY ||
    document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
  const handleScroll = () => {
    setCurrentScrollPos(window.scrollY);

    if (currentScrollPos < 900 || currentScrollPos > scrollMaxY - 100) {
      setVisible(false);
    } else if (width <= 480 && scrollDirection === "down") {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const scrollToBottom = () =>
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });

  return (
    <nav className={`${visible ? s.sticky : ""}`}>
      <div className={s.nav_container}>
        <div
          className={`${s.menu_bar} ${
            visible || !isMainPage ? "menu-padding" : ""
          }`}
        >
          <div
            className={s.logo}
            onClick={
              isMainPage
                ? () => window.scrollTo(0, 0)
                : () => window.open("/", "_self")
            }
          >
            <img src={LOGO} alt="logo OdyGo" />
          </div>
          <div className={s.small_menu}>
            <img src={menu} alt="menu" />
          </div>
          <div className={s.menu}>
            <MenuItemDropdown />
            {MENU_ITEMS.map((item, index) => (
              <a
                style={{ order: index }}
                key={index}
                className={s.menu_item}
                href={`${origin}/${item.link}`}
              >
                {item.name}
              </a>
            ))}

            <div className={s.icon_links} style={{ order: 6 }}>
              <Icons type="menu" />
            </div>
          </div>
        </div>
      </div>
      <div className={s.phone_container}>
        <div onClick={scrollToBottom}>{PHONE.image}</div>
      </div>
    </nav>
  );
}
