import { Article } from "../../../components/Article/Article";
import { PhotovoltaicsForHomeData } from "./PhotovoltaicsData";

export function PhotovoltaicsForHome() {
  return (
    <>
      <Article {...PhotovoltaicsForHomeData} />
    </>
  );
}
