import s from "./style.module.css";

export function Card({ text, image, altImage }) {
  return (
    <div className={s.card}>
      <img src={image} alt={altImage} />
      {text}
    </div>
  );
}
