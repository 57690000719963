import { Article } from "../../../components/Article/Article";
import { AirConditioningFarmerData } from "./DataAirConditioning";

export function FarmerAirConditioning() {
  return (
    <>
      <Article {...AirConditioningFarmerData} />
    </>
  );
}
