import s from "./style.module.css";
import logo from "../../images/icons/odygo.svg";
import { Icons } from "../Icons/Icons";
import { data } from "./data";
import { ContactUs } from "./ContactUs";
export function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className={s.bg}>
        <div className={`${s.container} ${s.flex} ${s.flex_column} ${s.gap_9}`}>
          {<ContactUs />}
          <div className={s.footer}>
            <div className={`${s.flex} ${s.gap_229}`}>
              <div>
                <img className={s.logo} src={logo} alt="logo OdyGo" />
                <p>
                  odyGO Maciej Odyjewski <br />
                  NIP: 5461393939
                </p>
              </div>
              <div
                className={`${s.flex} ${s.flex_column} ${s.gap_8} ${s.text}`}
              >
                {data.map((item, index) => (
                  <div
                    key={index}
                    className={`${s.info}`}
                    onClick={item.onClick}
                  >
                    <div className={s.icon}>
                      <img src={item.icon} alt={item.name} />
                    </div>
                    <p>{item.content}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${s.social_media} ${s.flex} ${s.gap_24}`}>
              <Icons type="footer" className={s.icon} />
            </div>
            <div className={`${s.copywrite} ${s.flex} ${s.gap_55}`}>
              <div className={s.small_text}>
                {year} © OdyGo, Wszystkie prawa zastrzeżone
              </div>

              <div className={s.small_text}>
                Polityka prywatności i plików Cookies
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
