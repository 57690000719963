import s from "./style.module.css";
import image from "../../images/page-not-found/404-vector.svg";
import linkArrow from "../../images/page-not-found/arrow.svg";

export function PageNotFound() {
  return (
    <>
      <header>
        <title>Page not found</title>
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
      </header>
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.title}>404</div>
          <div className={s.subtitle}>
            Nie możemy znaleźć tej strony - strona w budowie
          </div>
        </div>
        <img src={image} className={s.image} alt="Page not found" />
        {/* <div className={s.text_content}>
          <h3>Możliwe są 3 przyczyny:</h3>
          <ul className={s.list}>
            <li>strona jest w budowie</li>
            <li>strona została przeniesiona na inny adres</li>
            <li>strona nie istnieje</li>
          </ul>
        </div> */}
        <div className={s.link} onClick={() => window.open("/", "_self")}>
          <h3 href="/">wróć na stronę główną</h3>
          <img src={linkArrow} alt="link arrow" />
        </div>
      </div>
    </>
  );
}
