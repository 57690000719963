import { Article } from "../../../components/Article/Article";
import { DataLandTenancy } from "./DataLandTenancy";

export function LandTenancy() {
  return (
    <>
      <Article {...DataLandTenancy} />
    </>
  );
}
