import s from "./style.module.css";
import { HeroPage } from "../../page-components/HeroPage/HeroPage";
import { MAIN_PAGE_HEADERS } from "./LayoutData";

export function MainPage() {
  return (
    <>
      <div id="main">
        <header id="main-page">
          <HeroPage />
        </header>
        <main>
          {MAIN_PAGE_HEADERS.map((item, index) => {
            let headerItem = item.header;
            if (!item.hasOwnContainer) {
              headerItem = (
                <div className={s.header_container}>{headerItem}</div>
              );
            }
            if (item.hasBackground) {
              headerItem = <div className={s.header_bg}>{headerItem}</div>;
            }

            return (
              <section id={item.id} key={index}>
                {headerItem}
              </section>
            );
          })}
        </main>
      </div>
    </>
  );
}
