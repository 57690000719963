import { PrimaryButton } from "../PrimaryButton/PrimaryButton";

export function BottomButton({ text, onClick }) {
  const scrollToBottom = () => {
    if (onClick) {
      onClick();
    }

    window.scrollTo({
      top: document.body.scrollHeight,
    });
  };

  return <PrimaryButton text={text} onClick={scrollToBottom} />;
}
