import { Article } from "../../../components/Article/Article";
import { AirConditioningHomeData } from "./DataAirConditioning";

export function HomeAirConditioning() {
  return (
    <>
      <Article {...AirConditioningHomeData} />
    </>
  );
}
