import s from "./style.module.css";
import { PrimaryButton } from "../../components/PrimaryButton/PrimaryButton";

export function TemplateOffer({
  title,
  background,
  description,
  backgroundPosition,
  link,
}) {
  const backgroundStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: backgroundPosition ?? "50% 50%",
    width: "100%",
    height: "64rem",
    paddingTop: "6.4rem",
    paddingLeft: "6.4rem",
  };
  return (
    <>
      <div className="background-grey">
        <div className={s.main_container}>
          <div style={backgroundStyle}>
            <h2>{title}</h2>
          </div>
          <div className={s.baner}>
            <div className={s.baner_content}>
              <div className={`description-text ${s.description}`}>
                {description}
              </div>
              <PrimaryButton
                text="Dowiedz się więcej"
                onClick={() => window.open(link, "_self")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
