import s from "./style.module.css";
import line from "../../images/company-numbers/line.svg";
import CountUp from "react-countup";

export function Plus({ image, number, text, bg }) {
  const colors = [s.bg_0, s.bg_1, s.bg_2, s.bg_3, s.bg_4];

  return (
    <>
      <div className={`${s.container} ${colors[bg]}`}>
        <img src={image} alt={number + " " + text} />
        <img src={line} alt="line" />
        <div className={s.number}>
          <CountUp
            end={number}
            separator=""
            duration={3}
            enableScrollSpy={true}
            scrollSpyOnce={true}
            suffix={number === "100" ? "%" : ""}
          ></CountUp>
        </div>
        <div className={s.text}>{text}</div>
      </div>
    </>
  );
}
