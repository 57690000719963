import { DotationTemplate } from "../../page-components/DotationTemplate/DotationTemplate";
import { TemplateHeader } from "../../page-components/TemplateHeader/TemplateHeader";
import { TemplateOffer } from "../../page-components/TemplateOffer/TemplateOffer";
import { FARMER_HEADER, FARMER_DONTATIONS, FARMER_OFFERS } from "./FarmerData";

export function Farmer() {
  const header = (
    <TemplateHeader
      title={FARMER_HEADER.title}
      subtitle={FARMER_HEADER.description}
      backgroundUrl={FARMER_HEADER.background}
      backgroundPosition={FARMER_HEADER.backgroundPosition}
    />
  );

  const dontation = (
    <DotationTemplate
      title={FARMER_DONTATIONS.title}
      program={FARMER_DONTATIONS.program}
      description={FARMER_DONTATIONS.description}
      background={FARMER_DONTATIONS.background}
      backgroundPosition={FARMER_DONTATIONS.backgroundPosition}
    />
  );

  const offers = FARMER_OFFERS.map((x) => (
    <TemplateOffer
      {...x}
      // background={x.background}
      // title={x.title}
      // description={x.description}
      // backgroundPosition={x.backgroundPosition}
    />
  ));

  return (
    <>
      <header>{header}</header>
      <main>
        {dontation}
        {offers}
      </main>
    </>
  );
}
