import { Article } from "../../../components/Article/Article";
import { AirConditioningForCompanyData } from "./DataAirConditioning";

export function CompanyAirConditioning() {
  return (
    <>
      <Article {...AirConditioningForCompanyData} />
    </>
  );
}
