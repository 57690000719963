import s from "./style.module.css";
import img from "../../images/fotowoltaics/dom-fotowoltaika-panele-dach-odygo-bialystok-medium.webp";
import useWindowDimensions from "../../utils/useWindowDimensions";

export function PhotovoltaicsDefinition() {
  const { width } = useWindowDimensions();
  const isMobileWidth = width < 480;
  const image = (
    <img
      src={img}
      className={s.image}
      alt="dom z panelami fotowoltaicznymi"
    ></img>
  );

  return (
    <>
      <div className="container">
        <h2>Co to jest fotowoltaika?</h2>

        <div className={s.content}>
          <div className={s.text}>
            <p>
              Wiele się teraz słyszy o tej całej fotowoltaice, ale nie każdy
              wie, <b>jak to właściwie działa?</b> Fotowoltaika to technologia,
              która pozwala na zamianę <b>energii słonecznej</b> na{" "}
              <b>energię elektryczną</b>. W skrócie, panel fotowoltaiczny
              przetwarza promieniowanie słoneczne na prąd, który może być
              wykorzystany do zasilania różnych urządzeń elektrycznych w domu
              lub firmie.
            </p>
            {isMobileWidth && image}
            <p>
              Oczywiście, jednym z najważniejszych pytań jest,{" "}
              <b>czy fotowoltaika się opłaca?</b> Wiele zależy od indywidualnych
              potrzeb i uwarunkowań, ale w większości przypadków instalacja
              paneli fotowoltaicznych <b>pozwala na oszczędności</b> na
              rachunkach za prąd, a co więcej, może przynieść zyski z tzw.
              sprzedaży nadwyżek energii do sieci. Ponadto, fotowoltaika jest
              ekologicznym rozwiązaniem, które przyczynia się do{" "}
              <b>redukcji emisji gazów cieplarnianych</b> i dbałości o
              środowisko naturalne.
            </p>
            <p>
              W skrócie, fotowoltaika to technologia, która pozwala na
              pozyskiwanie energii z odnawialnego źródła jakim jest słońce, co
              przynosi korzyści finansowe i ekologiczne.
            </p>
          </div>
          {!isMobileWidth && image}
        </div>
      </div>
    </>
  );
}
