import { useEffect, useState } from "react";
import closeBtn from "../../images/pop-up/close-button.svg";
import s from "./style.module.css";
import { BottomButton } from "../../components/BottomButton/BottomButton";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function PopUp() {
  let isMainPage = window.location.pathname === "/";
  let isBanerClicked = sessionStorage.getItem("isBanerClicked");
  const [opacity, setOpacity] = useState(0);
  const [zIndex, setZIndex] = useState(-1);

  const onClick = () => {
    sessionStorage.setItem("isBanerClicked", true);
    document.getElementById("main").style.filter = "none";
    setZIndex(-1);
    setOpacity(0);
  };

  useEffect(() => {
    if (!isMainPage) {
      setOpacity(0);
      setZIndex(-1);
      return;
    }

    async function makeRequest() {
      await delay(2000);
      setOpacity(1);
      setZIndex(99);
    }
    if (!isBanerClicked) {
      makeRequest();
    }
  }, [isMainPage, isBanerClicked]);

  return (
    <div
      className={s.overlay}
      style={{
        opacity: opacity,
        zIndex: zIndex,
      }}
    >
      <div
        className={s.container}
        style={{
          background:
            "url(https://test.odygo.pl/inne/popup-dom-fotowoltaika-dach-zielona-energia-tani-prad-pompa-ciepla-bialystok-odygo.webp)",
          backgroundRepeat: "no-repeat",
          // opacity: opacity,
          // zIndex: zIndex,
        }}
      >
        <img
          src={closeBtn}
          alt="Close button"
          className={s.closeBtn}
          onClick={onClick}
        />
        <div className={s.popUp}>
          <h2>MEGA PROMOCJA!</h2>
          <h4>
            <b>Pakiet Fotowoltaika+Pompa ciepła</b>
          </h4>
          <div className={s.text}>
            Marzysz o wykorzystaniu energii słonecznej do ogrzewania swojego
            domu? A może chcesz osiągnąć niezależność energetyczną i zmniejszyć
            swoje rachunki za prąd?
          </div>
          <div className={s.textBold}>Mamy dla Ciebie idealne rozwiązanie!</div>
          <div className={s.text}>
            Teraz oferujemy Ci jeszcze więcej - pakiet fotowoltaiki w połączeniu
            z nowoczesną pompą ciepła, wszystko w jednym korzystnym pakiecie!
          </div>
          <div className={s.textBold}>Nie czekaj, zadzwoń już dziś!</div>
          <BottomButton onClick={onClick} text="Wyślij zapytanie!" />
        </div>
      </div>
    </div>
  );
}
