import { BottomButton } from "../../components/BottomButton/BottomButton";
import s from "./style.module.css";

export function TemplateHeader({
  title,
  subtitle,
  backgroundUrl,
  backgroundPosition,
}) {
  const backgroundStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: backgroundPosition ?? "50% 60%",
  };

  return (
    <>
      <div style={backgroundStyle} className={s.bg}>
        <div className={s.main_container}>
          <div className={s.container}>
            <div className={s.header_container}>
              <div className={s.title}>{title}</div>
              <div className={s.subtitle}>{subtitle}</div>
              <BottomButton text="Wyślij zapytanie" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
